
const state = () => ({
  personalCacheBy1P: {
    QueryModel: {},
    APlusContentQueryModel: {}
  },
  personalCacheBy3P: {
    QueryModel: {}
  }
})
const actions = {}
export default {
  state,
  actions,
  persist: {
    enabled: true,
    isCommon: false,
    isModuleStorage: true,
    watchFields: ['personalCacheBy1P', 'personalCacheBy3P'],
    isLogoutClearStorage: false
  }
}
