const getCommonState = () => {
  return {
    filterForm: '', // object
    brandType: ''
  }
}
const state = () => ({
  sellerState: getCommonState(),
  vendorState: getCommonState()
})

const actions = {

}

export default {
  state,
  actions,
  persist: {
    enabled: true,
    isCommon: false,
    isModuleStorage: true,
    isLogoutClearStorage: false,
    watchFields: ['sellerState', 'vendorState']
  }
}
