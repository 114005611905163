
const state = () => ({
  panelPlanListBy3p: {
    sns: [],
    normal: []
  },
  panelPlanListBy1p: {
    ManufacturingFresh: [],
    ManufacturingCore: [],
    ManufacturingBusiness: [],
    SourcingRetail: [],
    SourcingFresh: [],
    SourcingCore: [],
    SourcingBusiness: [],
    sns: [],
    normal: []
  },
  filterCache1p: {
    currentTab: '1',
    dateType: 'D',
    showChart: true,
    isFullNumber: false
  },
  filterCache3p: {
    currentTab: '1',
    filterCache: {
      dateType: 'D',
      hourlyType: 'Trend'
    },
    showAsinWithoutOrders: true,
    showChart: true,
    isFullNumber: false
  },
  panelCache1p: {},
  panelCache3p: {}
})

const actions = {
}

export default {
  state,
  actions,
  persist: {
    enabled: true,
    isCommon: false,
    isLogoutClearStorage: false,
    isModuleStorage: true,
    watchFields: ['panelPlanListBy3p', 'panelPlanListBy1p', 'filterCache1p', 'filterCache3p', 'panelCache1p', 'panelCache3p']
  }
}
