import { useStoreModule } from './util'
export { useAppStore } from '@pacvue/store'
import * as modules from './modules'
export const {
  useUserStore,
  useHomeStore,
  useCommonStore,
  useCommerceStore,
  useDriverStore,
  useDriverAlertStore,
  useDriverJBPStore,
  useCustomServiceStore: useCsStore,
  useAsinDetailStore,
  usePeriscopeStore,
  useShareStore,
  useMyReportStore,
  useTargetStore,
  useSalesStore,
  useRealTimeSalesStore,
  useAlertsMainStore,
  useSalesAsinTimeStore,
  useSalesTargetStore,
  usePromotionStore,
  useProfitabilityStore,
  useBrandOverviewStore,
  useCategoryOverviewStore,
  useInventoryStore,
  useGeoSalesOverviewStore,
  useKeywordAnalysisStore,
  useInventoryOverviewStore,
  useOptimizationOverviewStore,
  useBuyboxOverviewStore,
  useBrandAuditStore,
  useAccountViewStore,
  useBuyboxTrackerStore,
  useCatalogStore,
  useProductAuditStore,
  useDisputeOverviewStore,
  useClaimOverviewStore,
  useShareOfVoiceStore,
  useReviewsStore,
  useProfitabilityOverviewStore,
  useMerchandisingOverviewStore,
  useOrderStore,
  useCouponStore,
  useSnsOverviewStore,
  useProfitRecoupStore,
  usePoForecastStore,
  useConsumptionForecastStore,
  useAlertOverviewStore,
  useAlertHistoryStore,
  useCaseHistoryStore,
  useBillingStore
} = useStoreModule(modules)
