import Main from '@pacvue/frame/layout/Main.vue'
export default {
  path: '/Synchronization',
  component: Main,
  redirect: '/Synchronization',
  // name: 'Synchronization',
  meta: {
    title: 'Synchronization'
  },
  sort: 0,
  hidden: true,
  children: [
    {
      path: '/Synchronization',
      name: 'Synchronization',
      component: () => import('@!/views/Synchronization/index.vue'),
      meta: {
        isShowTime: false,
        autoTestPrefix: 'auto_bm176'
      }
    }
  ]
}
