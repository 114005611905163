const state = () => ({
  deduction: {
    currentTab: '',
    ShortageInvoiceFilterDate: {},
    ShortageInvoiceTableConfig: {},
    PriceClaimFilterDate: {},
    PriceClaimTableConfig: {},
    CoOpFilterDate: {},
    CoOpTableConfig: {},
    ReturnsFilterDate: {},
    ReturnsTableConfig: {},
    ChargebacksFilterDate: {},
    ChargebacksTableConfig: {},
    PostAuditClaimFilterDate: {},
    PostAuditClaimTableConfig: {}
  },
  claim: {
    currentTab: '',
    InboundFilterDate: {},
    LostFilterDate: {},
    DamagedFilterDate: {},
    ReturnFilterDate: {},
    ReplacementFilterDate: {},
    FeeDiscrepancyFilterDate: {},
    FbaFeeTableConfig: {},
    needActive: []
  },
  dispute: {
    filterDate: {}
  },
  payment: {
    filterDate: {}
  },
  claimCase: {
    filterDate: {}
  }
})
const actions = {}

export default {
  state,
  actions,
  persist: {
    enabled: true,
    isCommon: false,
    isModuleStorage: true,
    watchFields: ['deduction', 'claim', 'dispute', 'payment', 'claimCase'],
    isLogoutClearStorage: false
  }
}
