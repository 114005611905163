
const state = () => ({
  sellerState: {
    breadFilterForm: '', // object
    globalData: '', // object
    topTable: {
      active: ''
    },
    profitabilityTrendChart: {
      metricList: '' // array
    },
    profitabilitySalesChart: {
      metricList: '' // array
    },
    bubbleChart: {
      chartSetting: '' // object
    },
    topTenChart: {
      chartQuery: '' // object
    }
  },
  vendorState: {
    breadFilterForm: '', // object
    globalData: '', // object
    topTable: {
      active: ''
    },
    profitsPerformanceTrendChart: {
      metricList: '' // array
    },
    bubbleChart: {
      chartSetting: '' // object
    },
    topTenChart: {
      chartQuery: '' // object
    }
  }
})

const actions = {

}

export default {
  state,
  actions,
  persist: {
    enabled: true,
    isCommon: false,
    isModuleStorage: true,
    isLogoutClearStorage: false,
    watchFields: ['sellerState', 'vendorState']
  }
}
