import {
  PacvueIcon1Pmark,
  PacvueIcon3Pmark,
  PacvueIconAccessibleAccount,
  PacvueIconAccountProfilesSpendsReport,
  PacvueIconActivation,
  PacvueIconActiveExplorer,
  PacvueIconAdd,
  PacvueIconAddAsinAsPatTo,
  PacvueIconAddKeyword,
  PacvueIconAddToAsinTag,
  PacvueIconAddToCampaign,
  PacvueIconAddToKeywordTag,
  PacvueIconAddtotargeting,
  PacvueIconAdmin,
  PacvueIconAdRevenue,
  PacvueIconAdvertising,
  PacvueIconAfterDislike,
  PacvueIconAfterLike,
  PacvueIconAi,
  PacvueIconAlertNormal,
  PacvueIconAlertNotSet,
  PacvueIconAllExplorer,
  PacvueIconAllStores,
  PacvueIconAmazon,
  PacvueIconAmazondsp,
  PacvueIconAmazondspGray,
  PacvueIconAmazonGray,
  PacvueIconAmazonMedium,
  PacvueIconAmazonSmall,
  PacvueIconAnalysis,
  PacvueIconAnalysisMagnify,
  PacvueIconApiKey,
  PacvueIconApply,
  PacvueIconArrowDown,
  PacvueIconArrowDown1,
  PacvueIconArrowDownGroupm,
  PacvueIconArrowDropDown,
  PacvueIconArrowRight,
  PacvueIconArrowRight1,
  PacvueIconArrowRightGroupm,
  PacvueIconArrowUp,
  PacvueIconArrowUpGroupm,
  PacvueIconAscend,
  PacvueIconAsinExpansion,
  PacvueIconAsinReport,
  PacvueIconAsinWarning,
  PacvueIconAskAQuestionToGetInsight,
  PacvueIconAttributionReport,
  PacvueIconAuditAgain,
  PacvueIconAuditHistory,
  PacvueIconAutoRefillRule,
  PacvueIconAutorenew,
  PacvueIconBalance,
  PacvueIconBetaOrange,
  PacvueIconBetaWhite,
  PacvueIconBillingReport,
  PacvueIconBinding,
  PacvueIconBol,
  PacvueIconBoldFont,
  PacvueIconBolGray,
  PacvueIconBolMedium,
  PacvueIconBolSmall,
  PacvueIconBrandMetricsReport,
  PacvueIconBudgetManager,
  PacvueIconBudgetPlanning,
  PacvueIconBudgetSchedule,
  PacvueIconBulkCreationPriceTracker,
  PacvueIconBulkOperation,
  PacvueIconBulkOperation1,
  PacvueIconCalendarSetting,
  PacvueIconCampaignBudgetInsightReport,
  PacvueIconCampaignOutOfBudgetReport,
  PacvueIconCampaignOutOfDailyBudgetReport,
  PacvueIconCampaignReport,
  PacvueIconCampaignTagReport,
  PacvueIconCancel,
  PacvueIconCategoryIntelligence,
  PacvueIconCategoryReport,
  PacvueIconCheck,
  PacvueIconCheck1,
  PacvueIconCheck2,
  PacvueIconChevronsRight,
  PacvueIconChewy,
  PacvueIconChewyGray,
  PacvueIconChewyMedium,
  PacvueIconChewySmall,
  PacvueIconCitrus,
  PacvueIconCitrusGray,
  PacvueIconCitrusMedium,
  PacvueIconCitrusSmall,
  PacvueIconClose,
  PacvueIconCloseCircleFill,
  PacvueIconColumnChart,
  PacvueIconCommerceAccountReport,
  PacvueIconCommerceAlert,
  PacvueIconCommerceAlertManagement,
  PacvueIconCommerceAmazon,
  PacvueIconCommerceAmazonGrey,
  PacvueIconCommerceArrow,
  PacvueIconCommerceAz,
  PacvueIconCommerceBestSelling,
  PacvueIconCommerceBussiness,
  PacvueIconCommerceBuybox,
  PacvueIconCommerceCatalog,
  PacvueIconCommerceCategoryReport,
  PacvueIconCommerceClose,
  PacvueIconCommerceCloseSelected,
  PacvueIconCommerceCompetitive,
  PacvueIconCommerceConfirm,
  PacvueIconCommerceCreateTicket,
  PacvueIconCommerceCustomerService,
  PacvueIconCommerceDataDownloadError,
  PacvueIconCommerceDataDownloadStart,
  PacvueIconCommerceDataDownloadSuccess,
  PacvueIconCommerceDeclineDrivers,
  PacvueIconCommerceDigitalShelf,
  PacvueIconCommerceDownloadCenter,
  PacvueIconCommerceExpand,
  PacvueIconCommerceFiltered,
  PacvueIconCommerceFinance,
  PacvueIconCommerceFold,
  PacvueIconCommerceForecastReport,
  PacvueIconCommerceGoToClaim,
  PacvueIconCommerceGrowthDrivers,
  PacvueIconCommerceIcon1,
  PacvueIconCommerceIcon2,
  PacvueIconCommerceIcon3,
  PacvueIconCommerceInboundUpload,
  PacvueIconCommerceInboundUploadGrey,
  PacvueIconCommerceInput,
  PacvueIconCommerceMarketReport,
  PacvueIconCommerceMerchandisingProduct,
  PacvueIconCommerceNewProducts,
  PacvueIconCommerceNo,
  PacvueIconCommerceOpen,
  PacvueIconCommerceOpenSelected,
  PacvueIconCommercePacvueTools,
  PacvueIconCommercePin,
  PacvueIconCommercePlot,
  PacvueIconCommerceProductsInAd,
  PacvueIconCommerceReckittReport,
  PacvueIconCommerceReporting,
  PacvueIconCommerceRepriceSetting,
  PacvueIconCommerceReview,
  PacvueIconCommerceSales,
  PacvueIconCommerceSelect,
  PacvueIconCommerceSlowSelling,
  PacvueIconCommerceSnooze,
  PacvueIconCommerceStageComparison,
  PacvueIconCommerceSupplyChain,
  PacvueIconCommerceSupplyChainReport,
  PacvueIconCommerceSuspend,
  PacvueIconCommerceSynced,
  PacvueIconCommerceSynchronizeTo,
  PacvueIconCommerceTarget,
  PacvueIconCommerceTargetBack,
  PacvueIconCommerceTargetGray,
  PacvueIconCommerceWalmart,
  PacvueIconCommerceWalmartGrey,
  PacvueIconCommerceYes,
  PacvueIconCommerceZa,
  PacvueIconCompetitorAnalysis,
  PacvueIconComponent,
  PacvueIconConfirm,
  PacvueIconConnectAmazonAdvertising,
  PacvueIconConnectSellingPartner,
  PacvueIconContactUs,
  PacvueIconCopy,
  PacvueIconCreativeReport,
  PacvueIconCriteo,
  PacvueIconCriteoGray,
  PacvueIconCriteoMedium,
  PacvueIconCriteoSmall,
  PacvueIconCrossRetailer,
  PacvueIconCrossRetailerDashboard,
  PacvueIconCrossRetailerGray,
  PacvueIconCustomColumns,
  PacvueIconCustomDashbaordTopOverview,
  PacvueIconCustomDashbaordTopOverviewCircle,
  PacvueIconCustomDashboardComparisonChart,
  PacvueIconCustomDashboardComparisonChartCircle,
  PacvueIconCustomDashboardPieChart,
  PacvueIconCustomDashboardPieChartCircle,
  PacvueIconCustomDashboardStackedBarChart,
  PacvueIconCustomDashboardStackedBarChartCircle,
  PacvueIconCustomDashboardTable,
  PacvueIconCustomDashboardTableCircle,
  PacvueIconCustomDashboardTrendChart,
  PacvueIconCustomDashboardTrendChartCircle,
  PacvueIconCustomDashboardWhiteBoard,
  PacvueIconCustomDashboardWhiteBoardCircle,
  PacvueIconCustomScenario,
  PacvueIconCustomWidget,
  PacvueIconDataAlert,
  PacvueIconDataDownload,
  PacvueIconDaypartChartControl,
  PacvueIconDaypartDecBid,
  PacvueIconDaypartIncBid,
  PacvueIconDayParting,
  PacvueIconDaypartPlacement,
  PacvueIconDaypartSetBid,
  PacvueIconDefaultScenario,
  PacvueIconDelete,
  PacvueIconDescend,
  PacvueIconDesignateBrandTag,
  PacvueIconDetail,
  PacvueIconDetailChart,
  PacvueIconDirectFulfillmentReport,
  PacvueIconDislike,
  PacvueIconDocument,
  PacvueIconDoordash,
  PacvueIconDoordashGray,
  PacvueIconDoordashMedium,
  PacvueIconDoordashSmall,
  PacvueIconDoubleColumn,
  PacvueIconDownKey,
  PacvueIconDownload,
  PacvueIconDownloadDefaultReport,
  PacvueIconDownloadPdf,
  PacvueIconDraft,
  PacvueIconDrag,
  PacvueIconDrag1,
  PacvueIconDragIconReposition,
  PacvueIconDspMedium,
  PacvueIconEbay,
  PacvueIconEbayGray,
  PacvueIconEbayMedium,
  PacvueIconEbaySmall,
  PacvueIconEdit,
  PacvueIconEditdetails,
  PacvueIconEditProfile,
  PacvueIconElArrowLeft,
  PacvueIconEmail,
  PacvueIconEmptyBell,
  PacvueIconEnterKey,
  PacvueIconError,
  PacvueIconErrorTip,
  PacvueIconEvent,
  PacvueIconExampleReference,
  PacvueIconExcelIcon,
  PacvueIconExcelIcon1,
  PacvueIconExclamation,
  PacvueIconExclamationBlue,
  PacvueIconExclamationGrey,
  PacvueIconExecutive1,
  PacvueIconExecutiveCenter,
  PacvueIconExport,
  PacvueIconEye,
  PacvueIconFeedback1,
  PacvueIconFilter,
  PacvueIconFilterScenes,
  PacvueIconFloatingWindow,
  PacvueIconFollowUp,
  PacvueIconFromkeywordtag,
  PacvueIconFromNegativeKeyword,
  PacvueIconGainInsights,
  PacvueIconGetFeatureInstructions,
  PacvueIconGetInsight,
  PacvueIconGood1,
  PacvueIconHeadPacvue,
  PacvueIconHeatMap,
  PacvueIconHelpCenter,
  PacvueIconHome,
  PacvueIconHomereport,
  PacvueIconHomeSolid,
  PacvueIconHorizontalSwitching,
  PacvueIconIgnore,
  PacvueIconIncreaseBids,
  PacvueIconIncreaseReach,
  PacvueIconInspiration,
  PacvueIconInstacartMedium,
  PacvueIconInstacartSmall,
  PacvueIconInstcart,
  PacvueIconInstcartGray,
  PacvueIconItalics,
  PacvueIconItsumo,
  PacvueIconItsumoSmall,
  PacvueIconJbp,
  PacvueIconKeywordDetail,
  PacvueIconKeywordExpansion,
  PacvueIconKeywordsPerformanceReport,
  PacvueIconKeywordSymbol,
  PacvueIconKnowledge,
  PacvueIconKroger,
  PacvueIconKrogerGray,
  PacvueIconKrogerMedium,
  PacvueIconKrogerSmall,
  PacvueIconLastPeriodData,
  PacvueIconLeader,
  PacvueIconLeftArrow,
  PacvueIconLeftKey,
  PacvueIconLike,
  PacvueIconLineBarChart,
  PacvueIconLineChart,
  PacvueIconList,
  PacvueIconLoading,
  PacvueIconLocation,
  PacvueIconLock1,
  PacvueIconLog,
  PacvueIconLogo,
  PacvueIconLogo2,
  PacvueIconManage,
  PacvueIconMarketAnalysis,
  PacvueIconMarketShareReport,
  PacvueIconMarkread,
  PacvueIconMegads,
  PacvueIconMenuFold,
  PacvueIconMenuUnfold,
  PacvueIconMercado,
  PacvueIconMercadoGray,
  PacvueIconMercadoMedium,
  PacvueIconMercadoSmall,
  PacvueIconMerge,
  PacvueIconMessage,
  PacvueIconMissingPicture,
  PacvueIconMonthlyRollover,
  PacvueIconMore,
  PacvueIconMorePlatform,
  PacvueIconMoreVertical,
  PacvueIconMt360Small,
  PacvueIconMultipleEdit,
  PacvueIconNegativeTargeting,
  PacvueIconNetPpm,
  PacvueIconNoData,
  PacvueIconNoData1,
  PacvueIconNofillClose,
  PacvueIconNotification,
  PacvueIconNotification1,
  PacvueIconNotOverrideAsin,
  PacvueIconObtainingData,
  PacvueIconOnboardingguide,
  PacvueIconOpenNewTab,
  PacvueIconOptimization,
  PacvueIconOptimizeBidMultiplierForSpCampaignSelected,
  PacvueIconOptimizeBidMultiplierForSpCampaignUnSelected,
  PacvueIconOptimizeItemBidForSpAutoCampaignSelected,
  PacvueIconOptimizeItemBidForSpAutoCampaignUnSelected,
  PacvueIconOptimizeKeywordBidForSpManualCampaignSelected,
  PacvueIconOptimizeKeywordBidForSpManualCampaignUnSelected,
  PacvueIconOrderedRevenue,
  PacvueIconOutOfStockUpcReport,
  PacvueIconOverrideAsin,
  PacvueIconOverview,
  PacvueIconPacvueCollage,
  PacvueIconPacvueDataImpact,
  PacvueIconPacvueHq,
  PacvueIconPacvueStarLine,
  PacvueIconPagefail,
  PacvueIconPagesuccess,
  PacvueIconPageTypeReport,
  PacvueIconPageWarning,
  PacvueIconPdfIcon,
  PacvueIconPerformanceChart,
  PacvueIconPlacementReport,
  PacvueIconPlatform,
  PacvueIconPlay,
  PacvueIconPlus,
  PacvueIconPopularity,
  PacvueIconPortfolioReport,
  PacvueIconPriceTracker,
  PacvueIconProductCenter,
  PacvueIconProductCenterReport,
  PacvueIconProductEligibilityReport,
  PacvueIconProductInsight,
  PacvueIconProductsPerformanceReport,
  PacvueIconProfileReport,
  PacvueIconPromotion,
  PacvueIconPurchasedProductReport,
  PacvueIconPureRoasReport,
  PacvueIconQueryReport,
  PacvueIconQuickFind,
  PacvueIconRankflagtop1,
  PacvueIconRankflagtop2,
  PacvueIconRankflagtop3,
  PacvueIconRealTimeData,
  PacvueIconRealTimeSales,
  PacvueIconRealTimeSalesInventoryReport,
  PacvueIconRedo,
  PacvueIconReduceBids,
  PacvueIconRefresh,
  PacvueIconRefreshDataAndSendAgain,
  PacvueIconRemove,
  PacvueIconReplay,
  PacvueIconResearch,
  PacvueIconResearchNoData,
  PacvueIconReset,
  PacvueIconReset1,
  PacvueIconResubmit,
  PacvueIconRetailerDashboard,
  PacvueIconRightKey,
  PacvueIconRowHeight,
  PacvueIconRule,
  PacvueIconRuleLibrary,
  PacvueIconRunAgain,
  PacvueIconSaleUnits,
  PacvueIconSam,
  PacvueIconSamGray,
  PacvueIconSamsclubMedium,
  PacvueIconSamsclubSmall,
  PacvueIconSave,
  PacvueIconSbCategoryBenchmarkReport,
  PacvueIconScheduledTask,
  PacvueIconSearch,
  PacvueIconSelect,
  PacvueIconSelectBrand,
  PacvueIconSelectColumns,
  PacvueIconSend,
  PacvueIconSendAgain,
  PacvueIconSetBid,
  PacvueIconShareCenter,
  PacvueIconShippedRevenue,
  PacvueIconShowAbbreviateNumber,
  PacvueIconShowFullNumber,
  PacvueIconSideBarDsp,
  PacvueIconSideDisplay,
  PacvueIconSiderBar,
  PacvueIconSingleColumn,
  PacvueIconSkip1,
  PacvueIconSkip2,
  PacvueIconSovMenu,
  PacvueIconSovReport,
  PacvueIconSpHourlyReport,
  PacvueIconSponsoredDisplay,
  PacvueIconStackedBarChart,
  PacvueIconStar,
  PacvueIconStarfill,
  PacvueIconStarred,
  PacvueIconStickynote2,
  PacvueIconStop,
  PacvueIconSubDrag,
  PacvueIconSuccess,
  PacvueIconSuperAdmin,
  PacvueIconSuperWizardAi,
  PacvueIconSwitch,
  PacvueIconSwitchToPlan,
  PacvueIconSyncFrom,
  PacvueIconSynchronization,
  PacvueIconSystem,
  PacvueIconTabKey,
  PacvueIconTagKeywords,
  PacvueIconTagSymbol,
  PacvueIconTarget,
  PacvueIconTargetGrey,
  PacvueIconTargetingReport,
  PacvueIconTargetMedium,
  PacvueIconTargetSmall,
  PacvueIconTextFormat1,
  PacvueIconTextFormat2,
  PacvueIconTipsExclamation,
  PacvueIconTo,
  PacvueIconTopBarArrowDown,
  PacvueIconTopBarDownload,
  PacvueIconTraining,
  PacvueIconUnderscore,
  PacvueIconUnStarred,
  PacvueIconUnverified,
  PacvueIconUpKey,
  PacvueIconUpload,
  PacvueIconUserManagement,
  PacvueIconUsername,
  PacvueIconUserPosition,
  PacvueIconVerified,
  PacvueIconVerify,
  PacvueIconVerticalSwitching,
  PacvueIconViewDetails,
  PacvueIconViewHistory,
  PacvueIconViewLog,
  PacvueIconViewSov,
  PacvueIconVisibilityOff,
  PacvueIconWalmart,
  PacvueIconWalmartGray,
  PacvueIconWalmartMedium,
  PacvueIconWalmartSmall,
  PacvueIconWarehouseClaimHelp,
  PacvueIconWarning,
  PacvueIconWarning1,
  PacvueIconWarning2,
  PacvueIconWarning3,
  PacvueIconWarning4,
  PacvueIconWarningOrangeTriangle,
  PacvueIconWarningRedTriangle,
  PacvueIconWastedAdSpendReduceBids,
  PacvueIconWhiteBoard,
  PacvueIconWhitelistSettings,
  PacvueIconWidgetAcos,
  PacvueIconWidgetArrowRight,
  PacvueIconWidgetChart,
  PacvueIconWidgetClick,
  PacvueIconWidgetCtr,
  PacvueIconWidgetExpand,
  PacvueIconWidgetList,
  PacvueIconWidgetMore,
  PacvueIconWidgetSetting,
  PacvueIconWidgetShrink,
  PacvueIconWidgetSpend,
  PacvueIconWidgetTable,
  PacvueIconYearOverYearDataReport,
  PacvueIconYtdCumulativeReport,
  PacvueIconZhihuiDataAlert,
  PacvueIconZhihuiDataDownload,
  PacvueIconZhihuiNotification,
  PacvueIconZhihuiScheduledTask,
  PacvueIconZhihuiSystem
} from '@pacvue/svg-icon'
export default {
  PacvueIcon1Pmark,
  PacvueIcon3Pmark,
  PacvueIconAccessibleAccount,
  PacvueIconAccountProfilesSpendsReport,
  PacvueIconActivation,
  PacvueIconActiveExplorer,
  PacvueIconAdd,
  PacvueIconAddAsinAsPatTo,
  PacvueIconAddKeyword,
  PacvueIconAddToAsinTag,
  PacvueIconAddToCampaign,
  PacvueIconAddToKeywordTag,
  PacvueIconAddtotargeting,
  PacvueIconAdmin,
  PacvueIconAdRevenue,
  PacvueIconAdvertising,
  PacvueIconAfterDislike,
  PacvueIconAfterLike,
  PacvueIconAi,
  PacvueIconAlertNormal,
  PacvueIconAlertNotSet,
  PacvueIconAllExplorer,
  PacvueIconAllStores,
  PacvueIconAmazon,
  PacvueIconAmazondsp,
  PacvueIconAmazondspGray,
  PacvueIconAmazonGray,
  PacvueIconAmazonMedium,
  PacvueIconAmazonSmall,
  PacvueIconAnalysis,
  PacvueIconAnalysisMagnify,
  PacvueIconApiKey,
  PacvueIconApply,
  PacvueIconArrowDown,
  PacvueIconArrowDown1,
  PacvueIconArrowDownGroupm,
  PacvueIconArrowDropDown,
  PacvueIconArrowRight,
  PacvueIconArrowRight1,
  PacvueIconArrowRightGroupm,
  PacvueIconArrowUp,
  PacvueIconArrowUpGroupm,
  PacvueIconAscend,
  PacvueIconAsinExpansion,
  PacvueIconAsinReport,
  PacvueIconAsinWarning,
  PacvueIconAskAQuestionToGetInsight,
  PacvueIconAttributionReport,
  PacvueIconAuditAgain,
  PacvueIconAuditHistory,
  PacvueIconAutoRefillRule,
  PacvueIconAutorenew,
  PacvueIconBalance,
  PacvueIconBetaOrange,
  PacvueIconBetaWhite,
  PacvueIconBillingReport,
  PacvueIconBinding,
  PacvueIconBol,
  PacvueIconBoldFont,
  PacvueIconBolGray,
  PacvueIconBolMedium,
  PacvueIconBolSmall,
  PacvueIconBrandMetricsReport,
  PacvueIconBudgetManager,
  PacvueIconBudgetPlanning,
  PacvueIconBudgetSchedule,
  PacvueIconBulkCreationPriceTracker,
  PacvueIconBulkOperation,
  PacvueIconBulkOperation1,
  PacvueIconCalendarSetting,
  PacvueIconCampaignBudgetInsightReport,
  PacvueIconCampaignOutOfBudgetReport,
  PacvueIconCampaignOutOfDailyBudgetReport,
  PacvueIconCampaignReport,
  PacvueIconCampaignTagReport,
  PacvueIconCancel,
  PacvueIconCategoryIntelligence,
  PacvueIconCategoryReport,
  PacvueIconCheck,
  PacvueIconCheck1,
  PacvueIconCheck2,
  PacvueIconChevronsRight,
  PacvueIconChewy,
  PacvueIconChewyGray,
  PacvueIconChewyMedium,
  PacvueIconChewySmall,
  PacvueIconCitrus,
  PacvueIconCitrusGray,
  PacvueIconCitrusMedium,
  PacvueIconCitrusSmall,
  PacvueIconClose,
  PacvueIconCloseCircleFill,
  PacvueIconColumnChart,
  PacvueIconCommerceAccountReport,
  PacvueIconCommerceAlert,
  PacvueIconCommerceAlertManagement,
  PacvueIconCommerceAmazon,
  PacvueIconCommerceAmazonGrey,
  PacvueIconCommerceArrow,
  PacvueIconCommerceAz,
  PacvueIconCommerceBestSelling,
  PacvueIconCommerceBussiness,
  PacvueIconCommerceBuybox,
  PacvueIconCommerceCatalog,
  PacvueIconCommerceCategoryReport,
  PacvueIconCommerceClose,
  PacvueIconCommerceCloseSelected,
  PacvueIconCommerceCompetitive,
  PacvueIconCommerceConfirm,
  PacvueIconCommerceCreateTicket,
  PacvueIconCommerceCustomerService,
  PacvueIconCommerceDataDownloadError,
  PacvueIconCommerceDataDownloadStart,
  PacvueIconCommerceDataDownloadSuccess,
  PacvueIconCommerceDeclineDrivers,
  PacvueIconCommerceDigitalShelf,
  PacvueIconCommerceDownloadCenter,
  PacvueIconCommerceExpand,
  PacvueIconCommerceFiltered,
  PacvueIconCommerceFinance,
  PacvueIconCommerceFold,
  PacvueIconCommerceForecastReport,
  PacvueIconCommerceGoToClaim,
  PacvueIconCommerceGrowthDrivers,
  PacvueIconCommerceIcon1,
  PacvueIconCommerceIcon2,
  PacvueIconCommerceIcon3,
  PacvueIconCommerceInboundUpload,
  PacvueIconCommerceInboundUploadGrey,
  PacvueIconCommerceInput,
  PacvueIconCommerceMarketReport,
  PacvueIconCommerceMerchandisingProduct,
  PacvueIconCommerceNewProducts,
  PacvueIconCommerceNo,
  PacvueIconCommerceOpen,
  PacvueIconCommerceOpenSelected,
  PacvueIconCommercePacvueTools,
  PacvueIconCommercePin,
  PacvueIconCommercePlot,
  PacvueIconCommerceProductsInAd,
  PacvueIconCommerceReckittReport,
  PacvueIconCommerceReporting,
  PacvueIconCommerceRepriceSetting,
  PacvueIconCommerceReview,
  PacvueIconCommerceSales,
  PacvueIconCommerceSelect,
  PacvueIconCommerceSlowSelling,
  PacvueIconCommerceSnooze,
  PacvueIconCommerceStageComparison,
  PacvueIconCommerceSupplyChain,
  PacvueIconCommerceSupplyChainReport,
  PacvueIconCommerceSuspend,
  PacvueIconCommerceSynced,
  PacvueIconCommerceSynchronizeTo,
  PacvueIconCommerceTarget,
  PacvueIconCommerceTargetBack,
  PacvueIconCommerceTargetGray,
  PacvueIconCommerceWalmart,
  PacvueIconCommerceWalmartGrey,
  PacvueIconCommerceYes,
  PacvueIconCommerceZa,
  PacvueIconCompetitorAnalysis,
  PacvueIconComponent,
  PacvueIconConfirm,
  PacvueIconConnectAmazonAdvertising,
  PacvueIconConnectSellingPartner,
  PacvueIconContactUs,
  PacvueIconCopy,
  PacvueIconCreativeReport,
  PacvueIconCriteo,
  PacvueIconCriteoGray,
  PacvueIconCriteoMedium,
  PacvueIconCriteoSmall,
  PacvueIconCrossRetailer,
  PacvueIconCrossRetailerDashboard,
  PacvueIconCrossRetailerGray,
  PacvueIconCustomColumns,
  PacvueIconCustomDashbaordTopOverview,
  PacvueIconCustomDashbaordTopOverviewCircle,
  PacvueIconCustomDashboardComparisonChart,
  PacvueIconCustomDashboardComparisonChartCircle,
  PacvueIconCustomDashboardPieChart,
  PacvueIconCustomDashboardPieChartCircle,
  PacvueIconCustomDashboardStackedBarChart,
  PacvueIconCustomDashboardStackedBarChartCircle,
  PacvueIconCustomDashboardTable,
  PacvueIconCustomDashboardTableCircle,
  PacvueIconCustomDashboardTrendChart,
  PacvueIconCustomDashboardTrendChartCircle,
  PacvueIconCustomDashboardWhiteBoard,
  PacvueIconCustomDashboardWhiteBoardCircle,
  PacvueIconCustomScenario,
  PacvueIconCustomWidget,
  PacvueIconDataAlert,
  PacvueIconDataDownload,
  PacvueIconDaypartChartControl,
  PacvueIconDaypartDecBid,
  PacvueIconDaypartIncBid,
  PacvueIconDayParting,
  PacvueIconDaypartPlacement,
  PacvueIconDaypartSetBid,
  PacvueIconDefaultScenario,
  PacvueIconDelete,
  PacvueIconDescend,
  PacvueIconDesignateBrandTag,
  PacvueIconDetail,
  PacvueIconDetailChart,
  PacvueIconDirectFulfillmentReport,
  PacvueIconDislike,
  PacvueIconDocument,
  PacvueIconDoordash,
  PacvueIconDoordashGray,
  PacvueIconDoordashMedium,
  PacvueIconDoordashSmall,
  PacvueIconDoubleColumn,
  PacvueIconDownKey,
  PacvueIconDownload,
  PacvueIconDownloadDefaultReport,
  PacvueIconDownloadPdf,
  PacvueIconDraft,
  PacvueIconDrag,
  PacvueIconDrag1,
  PacvueIconDragIconReposition,
  PacvueIconDspMedium,
  PacvueIconEbay,
  PacvueIconEbayGray,
  PacvueIconEbayMedium,
  PacvueIconEbaySmall,
  PacvueIconEdit,
  PacvueIconEditdetails,
  PacvueIconEditProfile,
  PacvueIconElArrowLeft,
  PacvueIconEmail,
  PacvueIconEmptyBell,
  PacvueIconEnterKey,
  PacvueIconError,
  PacvueIconErrorTip,
  PacvueIconEvent,
  PacvueIconExampleReference,
  PacvueIconExcelIcon,
  PacvueIconExcelIcon1,
  PacvueIconExclamation,
  PacvueIconExclamationBlue,
  PacvueIconExclamationGrey,
  PacvueIconExecutive1,
  PacvueIconExecutiveCenter,
  PacvueIconExport,
  PacvueIconEye,
  PacvueIconFeedback1,
  PacvueIconFilter,
  PacvueIconFilterScenes,
  PacvueIconFloatingWindow,
  PacvueIconFollowUp,
  PacvueIconFromkeywordtag,
  PacvueIconFromNegativeKeyword,
  PacvueIconGainInsights,
  PacvueIconGetFeatureInstructions,
  PacvueIconGetInsight,
  PacvueIconGood1,
  PacvueIconHeadPacvue,
  PacvueIconHeatMap,
  PacvueIconHelpCenter,
  PacvueIconHome,
  PacvueIconHomereport,
  PacvueIconHomeSolid,
  PacvueIconHorizontalSwitching,
  PacvueIconIgnore,
  PacvueIconIncreaseBids,
  PacvueIconIncreaseReach,
  PacvueIconInspiration,
  PacvueIconInstacartMedium,
  PacvueIconInstacartSmall,
  PacvueIconInstcart,
  PacvueIconInstcartGray,
  PacvueIconItalics,
  PacvueIconItsumo,
  PacvueIconItsumoSmall,
  PacvueIconJbp,
  PacvueIconKeywordDetail,
  PacvueIconKeywordExpansion,
  PacvueIconKeywordsPerformanceReport,
  PacvueIconKeywordSymbol,
  PacvueIconKnowledge,
  PacvueIconKroger,
  PacvueIconKrogerGray,
  PacvueIconKrogerMedium,
  PacvueIconKrogerSmall,
  PacvueIconLastPeriodData,
  PacvueIconLeader,
  PacvueIconLeftArrow,
  PacvueIconLeftKey,
  PacvueIconLike,
  PacvueIconLineBarChart,
  PacvueIconLineChart,
  PacvueIconList,
  PacvueIconLoading,
  PacvueIconLocation,
  PacvueIconLock1,
  PacvueIconLog,
  PacvueIconLogo,
  PacvueIconLogo2,
  PacvueIconManage,
  PacvueIconMarketAnalysis,
  PacvueIconMarketShareReport,
  PacvueIconMarkread,
  PacvueIconMegads,
  PacvueIconMenuFold,
  PacvueIconMenuUnfold,
  PacvueIconMercado,
  PacvueIconMercadoGray,
  PacvueIconMercadoMedium,
  PacvueIconMercadoSmall,
  PacvueIconMerge,
  PacvueIconMessage,
  PacvueIconMissingPicture,
  PacvueIconMonthlyRollover,
  PacvueIconMore,
  PacvueIconMorePlatform,
  PacvueIconMoreVertical,
  PacvueIconMt360Small,
  PacvueIconMultipleEdit,
  PacvueIconNegativeTargeting,
  PacvueIconNetPpm,
  PacvueIconNoData,
  PacvueIconNoData1,
  PacvueIconNofillClose,
  PacvueIconNotification,
  PacvueIconNotification1,
  PacvueIconNotOverrideAsin,
  PacvueIconObtainingData,
  PacvueIconOnboardingguide,
  PacvueIconOpenNewTab,
  PacvueIconOptimization,
  PacvueIconOptimizeBidMultiplierForSpCampaignSelected,
  PacvueIconOptimizeBidMultiplierForSpCampaignUnSelected,
  PacvueIconOptimizeItemBidForSpAutoCampaignSelected,
  PacvueIconOptimizeItemBidForSpAutoCampaignUnSelected,
  PacvueIconOptimizeKeywordBidForSpManualCampaignSelected,
  PacvueIconOptimizeKeywordBidForSpManualCampaignUnSelected,
  PacvueIconOrderedRevenue,
  PacvueIconOutOfStockUpcReport,
  PacvueIconOverrideAsin,
  PacvueIconOverview,
  PacvueIconPacvueCollage,
  PacvueIconPacvueDataImpact,
  PacvueIconPacvueHq,
  PacvueIconPacvueStarLine,
  PacvueIconPagefail,
  PacvueIconPagesuccess,
  PacvueIconPageTypeReport,
  PacvueIconPageWarning,
  PacvueIconPdfIcon,
  PacvueIconPerformanceChart,
  PacvueIconPlacementReport,
  PacvueIconPlatform,
  PacvueIconPlay,
  PacvueIconPlus,
  PacvueIconPopularity,
  PacvueIconPortfolioReport,
  PacvueIconPriceTracker,
  PacvueIconProductCenter,
  PacvueIconProductCenterReport,
  PacvueIconProductEligibilityReport,
  PacvueIconProductInsight,
  PacvueIconProductsPerformanceReport,
  PacvueIconProfileReport,
  PacvueIconPromotion,
  PacvueIconPurchasedProductReport,
  PacvueIconPureRoasReport,
  PacvueIconQueryReport,
  PacvueIconQuickFind,
  PacvueIconRankflagtop1,
  PacvueIconRankflagtop2,
  PacvueIconRankflagtop3,
  PacvueIconRealTimeData,
  PacvueIconRealTimeSales,
  PacvueIconRealTimeSalesInventoryReport,
  PacvueIconRedo,
  PacvueIconReduceBids,
  PacvueIconRefresh,
  PacvueIconRefreshDataAndSendAgain,
  PacvueIconRemove,
  PacvueIconReplay,
  PacvueIconResearch,
  PacvueIconResearchNoData,
  PacvueIconReset,
  PacvueIconReset1,
  PacvueIconResubmit,
  PacvueIconRetailerDashboard,
  PacvueIconRightKey,
  PacvueIconRowHeight,
  PacvueIconRule,
  PacvueIconRuleLibrary,
  PacvueIconRunAgain,
  PacvueIconSaleUnits,
  PacvueIconSam,
  PacvueIconSamGray,
  PacvueIconSamsclubMedium,
  PacvueIconSamsclubSmall,
  PacvueIconSave,
  PacvueIconSbCategoryBenchmarkReport,
  PacvueIconScheduledTask,
  PacvueIconSearch,
  PacvueIconSelect,
  PacvueIconSelectBrand,
  PacvueIconSelectColumns,
  PacvueIconSend,
  PacvueIconSendAgain,
  PacvueIconSetBid,
  PacvueIconShareCenter,
  PacvueIconShippedRevenue,
  PacvueIconShowAbbreviateNumber,
  PacvueIconShowFullNumber,
  PacvueIconSideBarDsp,
  PacvueIconSideDisplay,
  PacvueIconSiderBar,
  PacvueIconSingleColumn,
  PacvueIconSkip1,
  PacvueIconSkip2,
  PacvueIconSovMenu,
  PacvueIconSovReport,
  PacvueIconSpHourlyReport,
  PacvueIconSponsoredDisplay,
  PacvueIconStackedBarChart,
  PacvueIconStar,
  PacvueIconStarfill,
  PacvueIconStarred,
  PacvueIconStickynote2,
  PacvueIconStop,
  PacvueIconSubDrag,
  PacvueIconSuccess,
  PacvueIconSuperAdmin,
  PacvueIconSuperWizardAi,
  PacvueIconSwitch,
  PacvueIconSwitchToPlan,
  PacvueIconSyncFrom,
  PacvueIconSynchronization,
  PacvueIconSystem,
  PacvueIconTabKey,
  PacvueIconTagKeywords,
  PacvueIconTagSymbol,
  PacvueIconTarget,
  PacvueIconTargetGrey,
  PacvueIconTargetingReport,
  PacvueIconTargetMedium,
  PacvueIconTargetSmall,
  PacvueIconTextFormat1,
  PacvueIconTextFormat2,
  PacvueIconTipsExclamation,
  PacvueIconTo,
  PacvueIconTopBarArrowDown,
  PacvueIconTopBarDownload,
  PacvueIconTraining,
  PacvueIconUnderscore,
  PacvueIconUnStarred,
  PacvueIconUnverified,
  PacvueIconUpKey,
  PacvueIconUpload,
  PacvueIconUserManagement,
  PacvueIconUsername,
  PacvueIconUserPosition,
  PacvueIconVerified,
  PacvueIconVerify,
  PacvueIconVerticalSwitching,
  PacvueIconViewDetails,
  PacvueIconViewHistory,
  PacvueIconViewLog,
  PacvueIconViewSov,
  PacvueIconVisibilityOff,
  PacvueIconWalmart,
  PacvueIconWalmartGray,
  PacvueIconWalmartMedium,
  PacvueIconWalmartSmall,
  PacvueIconWarehouseClaimHelp,
  PacvueIconWarning,
  PacvueIconWarning1,
  PacvueIconWarning2,
  PacvueIconWarning3,
  PacvueIconWarning4,
  PacvueIconWarningOrangeTriangle,
  PacvueIconWarningRedTriangle,
  PacvueIconWastedAdSpendReduceBids,
  PacvueIconWhiteBoard,
  PacvueIconWhitelistSettings,
  PacvueIconWidgetAcos,
  PacvueIconWidgetArrowRight,
  PacvueIconWidgetChart,
  PacvueIconWidgetClick,
  PacvueIconWidgetCtr,
  PacvueIconWidgetExpand,
  PacvueIconWidgetList,
  PacvueIconWidgetMore,
  PacvueIconWidgetSetting,
  PacvueIconWidgetShrink,
  PacvueIconWidgetSpend,
  PacvueIconWidgetTable,
  PacvueIconYearOverYearDataReport,
  PacvueIconYtdCumulativeReport,
  PacvueIconZhihuiDataAlert,
  PacvueIconZhihuiDataDownload,
  PacvueIconZhihuiNotification,
  PacvueIconZhihuiScheduledTask,
  PacvueIconZhihuiSystem
}
