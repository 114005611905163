import Highcharts from 'highcharts'
import { cloneDeep } from 'lodash-es'
const { VITE_APP_NODE_ENV } = import.meta.env

// 修改 highChart 原型拖拽句柄左右图标
function getHighChartCustomHandleSvg(x2, y2, w2, h2) {
  const size = 3
  return [
    'M',
    x2,
    y2 + size,
    'C',
    x2,
    y2,
    x2 + size,
    y2,
    x2 + size,
    y2,
    'L',
    x2 + 2 * size,
    y2,
    'C',
    x2 + 3 * size,
    y2,
    x2 + 3 * size,
    y2 + size,
    x2 + 3 * size,
    y2 + size,
    'L',
    x2 + 3 * size,
    y2 + 6 * size,
    'C',
    x2 + 3 * size,
    y2 + 7 * size,
    x2 + 2 * size,
    y2 + 7 * size,
    x2 + 2 * size,
    y2 + 7 * size,
    'L',
    x2 + size,
    y2 + 7 * size,
    'C',
    x2,
    y2 + 7 * size,
    x2,
    y2 + 6 * size,
    x2,
    y2 + 6 * size,
    'L',
    x2,
    y2 + size
  ]
}

const isProd = VITE_APP_NODE_ENV === 'production'
// @vitest-environment jsdom
Highcharts.setOptions({
  chart: {
    spacingLeft: 0,
    spacingRight: 0
  },
  colors: ['#FF9F43', '#28C76F', '#00CFE8', '#FFE802', '#FF9B9E', '#9B88FA', '#84D0FF'],
  exporting: {
    enabled: false
  },
  // 版权信息
  credits: {
    enabled: false
  },
  title: {
    text: null
  },
  xAxis: {
    labels: {
      style: {
        fontSize: '12px',
        fontFamily: 'Inter,  Helvetica,  Arial, Inter-Regular',
        fontWeight: 400,
        color: '#b2b2b8',
        lineHeight: '18px'
      }
    },
    gridLineColor: '#f8f8f8',
    lineColor: '#f8f8f8',
    tickLength: 0
  },
  yAxis: {
    title: {
      text: null
    },
    labels: {
      style: {
        color: '#b2b2b8'
      }
    },
    lineColor: '#f8f8f8',
    gridLineColor: '#f8f8f8'
  },
  tooltip: {
    shared: true,
    useHTML: true,
    padding: 0,
    backgroundColor: 'rgba(255,255,255,0.80)',
    borderColor: 'transparent',
    borderRadius: 6,
    shadow: false,
    animation: true,
    style: {
      fontSize: '12px',
      fontFamily: 'Inter, Helvetica, Arial, Inter-Regular',
      fontWeight: 400,
      color: '#66666c',
      boxShadow: '0px 4px 24px 0px rgba(0,0,0,0.06)'
    },
    // 跟随鼠标
    followPointer: true
  },
  legend: {
    padding: 0,
    symbolHeight: 10,
    itemStyle: {
      color: '#b2b2b8',
      fontSize: '12px',
      fontFamily: 'Inter, Helvetica, Arial, Inter-Regular',
      fontWeight: 400,
      lineHeight: '18px'
    }
  },
  plotOptions: {
    areaspline: {
      lineWidth: 1
    },
    series: {
      // Disable this option to allow series rendering in the whole plotting area.
      // Note: Clipping should be always enabled when chart.zoomType is set
      clip: false
    }
  }
})
Highcharts.AST.allowedAttributes.push('data-value')
Highcharts.AST.allowedAttributes.push('fill-rule')
Highcharts.AST.allowedAttributes.push('clip-rule')
Highcharts.AST.allowedAttributes.push('viewBox')
Highcharts.AST.allowedReferences.push('data:')
Highcharts.SVGRenderer.prototype.symbols.customHandle = getHighChartCustomHandleSvg

// 初始化 HighChart
const chartInit = Highcharts.chart
// 本初始化方法用于优化Highcharts.chart方法在初始化时，获取不到dom时报#13错误的问题
Highcharts.chart = function(...args) {
  let _args = cloneDeep(args)
  const [renderTo, options, callback] = _args
  let _options = options
  if (renderTo && this.isString(renderTo)) {
    // Highcharts.chart(renderTo, options, callback) 传参情况
    const dom = queryDomByIdString(renderTo)
    if (!dom) {
      !isProd && console.warn(`[Highcharts] Can't find the dom element by id: ${renderTo}`)
      return null
    }
    _args = [dom, options, callback]
  } else if (renderTo && this.isObject(renderTo) && !renderTo.querySelector && !callback) {
    // Highcharts.chart(options, callback) 传参情况
    const _renderTo = renderTo?.chart?.renderTo
    if (!_renderTo) {
      !isProd && console.warn('[Highcharts] Can\'t find the renderTo property in options')
      return null
    }
    if (_renderTo && this.isString(_renderTo)) {
      const dom = queryDomByIdString(_renderTo)
      if (!dom) {
        !isProd && console.warn(`[Highcharts] Can't find the dom element by id: ${_renderTo}`)
        return null
      }
    }
    _options = renderTo
  } else if (!renderTo) {
    !isProd && console.warn('[Highcharts] Can\'t find the renderTo property in options')
    return null
  }
  const { navigator, chart } = _options
  // 判断如果有navigator，则需要直接在两侧补一个间距，否则navigator的宽度会超出图表
  const padding = !!navigator
  const isLine = ['line', 'spline', 'areaspline', 'area'].includes(chart?.type)
  if (_options && navigator && isLine) {
    _options.chart = {
      ...(chart ?? {}),
      ...(padding && {
        spacingLeft: 10,
        spacingRight: 10
      })
    }
  }
  return chartInit.call(this, ..._args)
}
// 根据id获取dom辅助方法
function queryDomByIdString(renderTo) {
  const dom = document.getElementById(renderTo)
  if (!dom) {
    return null
  }
  return dom
}

// vitest in-source testing
if (import.meta.vitest) {
  // 用于测试优化#13错误的问题初始化方法的单元测试用例
  const { describe, expect, it, test } = import.meta.vitest
  describe('Highcharts.chart', () => {
    const mockDiv = document.createElement('div')
    mockDiv.setAttribute('id', 'mock-chart')
    document.body.appendChild(mockDiv)
    // 1. null result
    it('should return null result when pass one param', () => {
      expect(Highcharts.chart('not-found')).toBeNull()
      expect(Highcharts.chart(null)).toBeNull()
      expect(Highcharts.chart(undefined)).toBeNull()
      expect(Highcharts.chart({ chart: { renderTo: 'not-found' }})).toBeNull()
      expect(Highcharts.chart({ chart: { renderTo: null }})).toBeNull()
      expect(Highcharts.chart({ chart: { renderTo: undefined }})).toBeNull()
    })
    it('should return null result when pass two params', () => {
      expect(Highcharts.chart('not-found', {})).toBeNull()
      expect(Highcharts.chart(null, {})).toBeNull()
      expect(Highcharts.chart(undefined, {})).toBeNull()
      expect(Highcharts.chart({ chart: { renderTo: 'not-found' }}, () => {})).toBeNull()
      expect(Highcharts.chart({ chart: { renderTo: null }}, () => {})).toBeNull()
      expect(Highcharts.chart({ chart: { renderTo: undefined }}, () => {})).toBeNull()
    })
    it('should return null result when pass three params', () => {
      expect(Highcharts.chart('not-found', {}, () => {})).toBeNull()
      expect(Highcharts.chart(null, {}, () => {})).toBeNull()
      expect(Highcharts.chart(undefined, {}, () => {})).toBeNull()
    })
    // 2. not null result
    const options_at_first_idString = {
      chart: {
        renderTo: 'mock-chart'
      }
    }
    const options_at_first_dom = {
      chart: {
        renderTo: mockDiv
      }
    }
    const options_at_second = {
      plotOptions: {
        series: {
          clip: true
        }
      }
    }
    it('should return chart instance when pass one param', () => {
      expect(Highcharts.chart(options_at_first_idString)).not.toBeNull()
      expect(Highcharts.chart(options_at_first_dom)).not.toBeNull()
    })
    it('should return chart instance when pass two params', () => {
      expect(Highcharts.chart('mock-chart', options_at_second)).not.toBeNull()
      expect(Highcharts.chart(mockDiv, options_at_second)).not.toBeNull()
      expect(Highcharts.chart(mockDiv, options_at_second)).not.toBeNull()
      expect(Highcharts.chart(options_at_first_idString, () => {})).not.toBeNull()
      expect(Highcharts.chart(options_at_first_dom, () => {})).not.toBeNull()
    })
    it('should return chart instance when pass three params', () => {
      expect(Highcharts.chart('mock-chart', options_at_second, () => {})).not.toBeNull()
      expect(Highcharts.chart(mockDiv, options_at_second, () => {})).not.toBeNull()
      expect(Highcharts.chart(mockDiv, options_at_second, () => {})).not.toBeNull()
    })
  })
}
