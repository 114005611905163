import { InitApp } from '@pacvue/frame'
import permission from '@pacvue/frame/permission.js'
import routerFunction from '@pacvue/frame/routerFunction'
import createPinia, { useCommonStore, useAppStore, useUserStore } from '@!/store'
import userManageRouter from '@permission/permission.js'
import customDashboardRouter from '@customDashboard/customDashboardRouter.js'
import '@commerceCommon/utils/shimMethods'
import { registerPending, requestDone } from '@commerceCommon/utils/cancelToken'
import Login from '@pacvue/frame/login'
import essentialPlugin from '@pacvue/frame/essentialPlugin'
import useScreen from '@commerceCommon/utils/useScreen.js'
import useExternalToken from '@commerceCommon/utils/useExternalToken.js'
import { clearPending } from '@commerceCommon/utils/cancelToken.js'
import useWebClientId from '@commerceCommon/utils/useWebClientId'
import i18nPlugin from '@!/plugins/i18n/index.js'
import datadogPlugin from '@!/plugins/datadog/index.js'
import coralogixPlugin from '@!/plugins/coralogix/index.js'
import { useAnalytics, analyticsIdentify, useAnalyticsPageWithRouterName } from '@pacvue/frame/const'
import MessageRouter from '@Message/message.js'
import eventRouter from '@event/eventRouter.js'
import { initWebSocket } from '@!/utils/websocket.js'
import config from '../config'
import useDatePickerOptionsInit from '@!/utils/useDatePickerOptionsInit'
import router from './router'
import '@commerceCommon/utils/highcharts.js'
import '@!/assets/css/main.scss'
import 'driver.js/dist/driver.min.css'
import '@!/assets/css/driver.scss'
import commercePlugin, { PAGE_TYPE_MAPPER, useAnalyticsPageByStore, getPageType } from './commercePlugin.js'
import 'vue-cropper/dist/index.css'

localStorage.setItem('productline', 'commerce')

// 清除 next14- 特殊域名白名单用户的amazonBrand/amazonCategory缓存
;(function() {
  const uid = localStorage.getItem('uid')
  if (uid) {
    const { seller, vendor } = JSON.parse(localStorage.getItem(`${uid}_originBrandCategoryValueMap`) || '{}')
    if (window.abortUseAmazonFilters) {
      const newValue = {
        seller: {
          ...seller,
          brand: [],
          category: []
        },
        vendor: {
          ...vendor,
          brand: [],
          category: []
        }
      }
      localStorage.setItem(`${uid}_originBrandCategoryValueMap`, JSON.stringify(newValue))
    }
  }
})()

/**
 * 获取外部跳转的token
 */
useExternalToken()

/**
 * 生成客户端唯一标识
 */
const webClientId = useWebClientId()

InitApp.extend(Login)
InitApp.extend(userManageRouter)
InitApp.extend(MessageRouter)
InitApp.extend(customDashboardRouter)
InitApp.extend(eventRouter)
InitApp.extend(essentialPlugin)
InitApp.extend(commercePlugin)
InitApp.integrateRouterInterceptors()

permission(router, routerFunction.bind({ clearingFn: clearPending, hasPermissionProxy: true }))

const app = InitApp.init(router, '#vm', createPinia)
const commonStore = useCommonStore()
const userStore = useUserStore()
commonStore.mountCustomHeader()
const appStore = useAppStore()
// 初始化右侧面包屑日期组件配置
useDatePickerOptionsInit(appStore)
// 初始化页面埋点 - store
useAnalyticsPageByStore({
  commonStore,
  router
})

useScreen()
useAnalytics()

app.use(i18nPlugin)
app.use(datadogPlugin)
app.use(coralogixPlugin)

app.config.globalProperties.$eventHub.on('requestHook', function(config) {
  config.headers['Web-Client-Id'] = webClientId
  /**
   * 预发布环境和US正式环境使用同一套后端接口，新增istio-pacvue-version用于区分是否是预发布环境
   */
  if (import.meta.env.VITE_APP_RELEASE_ENV === 'beta') {
    config.headers['istio-pacvue-version'] = 'beta'
  }
  if (userStore.user?.byPacvuer) {
    config.headers['byPacvuer'] = userStore.user.byPacvuer
  }
  if (config.url.includes('/user/menus')) {
    config.headers.sourcetype = 8
  }
  /*
    中台 @Message/api 的 getMessage 接口不能取消
    因为两个vue文件用的同一个接口
    取消请求会导致客户在 /Message 页面刷新 loading 动画不终止
  */
  if (config.url.includes('/notification/getMessages')) {
    return
  }
  registerPending(config)
})

app.config.globalProperties.$eventHub.on('responseHook', function({ config }) {
  requestDone(config)
})

app.config.globalProperties.$eventHub.on('routerAfterEachHook', ({ to, from, failure }) => {
  const name = to?.name || to?.meta?.title

  // beforeEach中加入了，此段应该没有意义了
  if (!window.__analyticsIdentify__ && name !== 'login') {
    analyticsIdentify()
  }
  if (config.analytics && name) {
    if (Object.keys(PAGE_TYPE_MAPPER).includes(name)) {
      const pageType = { 'page business type': PAGE_TYPE_MAPPER[name] }
      useAnalyticsPageWithRouterName(name, pageType)
    } else if (name === 'ReportHistory') {
      useAnalyticsPageWithRouterName(name, { 'source page': window.document.referrer })
    } else {
      useAnalyticsPageWithRouterName(name, getPageType())
    }
  }
  // 刷新页面后需要重新建立websocket连接
  if (!window.commerceWebSocket && name !== 'login') {
    initWebSocket()
  }
})

app.config.warnHandler = () => {}
