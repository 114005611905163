export default {
  US: '$',
  UK: '£',
  TR: 'TL',
  SG: 'S$',
  SE: 'Kr',
  SA: 'SAR',
  PL: 'PL',
  NL: '€',
  MX: 'MX$',
  JP: '¥',
  IT: '€',
  IN: '₹',
  FR: '€',
  ES: '€',
  EG: 'EGP',
  DE: '€',
  // 无对应市场
  CN: '￥',
  CA: 'C$',
  BR: 'R$',
  BE: '€',
  AU: 'A$',
  AE: 'AED',
  IE: '£',
  ZA: 'R'
}
