const getCommonState = () => {
  return {
    byReviews: {
      filterForm: '' // object
    },
    byProduct: {
      filterForm: '' // object
    },
    dateType: '',
    metricList: '', // array
    activeName: ''
  }
}
const state = () => ({
  sellerState: getCommonState(),
  vendorState: getCommonState()
})

const actions = {

}

export default {
  state,
  actions,
  persist: {
    enabled: true,
    isCommon: false,
    isModuleStorage: true,
    isLogoutClearStorage: false,
    watchFields: ['sellerState', 'vendorState']
  }
}
