import { destroyChart } from '@!/utils/ts-common'
import Main from '@pacvue/frame/layout/Main.vue'
export default {
  path: '/Competitive',
  component: Main,
  meta: {
    auth: true,
    id: 666
  },
  sort: 800,
  redirect: '/Competitive/CompetitorTracker',
  children: [
    {
      path: '/Competitive/CompetitorTracker',
      component: () => destroyChart(() => import('@!/views/Competitive/CompetitorTracker/index.vue')),
      name: 'CompetitorTracker',
      meta: {
        autoTestPrefix: 'auto_bm132'
      }
    },
    {
      path: '/Competitive/KeywordAnalysis',
      component: () => import('@!/views/Competitive/KeywordAnalysis/index.vue'),
      name: 'KeywordAnalysis',
      meta: {
        autoTestPrefix: 'auto_bm133',
        storeName: 'keywordAnalysis'
      }
    },
    {
      path: '/Competitive/ShareOfVoice',
      component: () => import('@!/views/Competitive/ShareOfVoice/index.vue'),
      name: 'ShareOfVoice',
      meta: {
        autoTestPrefix: 'auto_bm134'
      }
    },
    {
      path: '/Competitive/MarketShare',
      component: () => import('@!/views/Competitive/NielseniqAnalytics/MarketShare/index.vue'),
      name: 'MarketShare',
      meta: {
        autoTestPrefix: 'auto_bm135'
      }
    }
  ]
} as import('vue-router').RouteLocationPathRaw
