
const state = () => ({
  vendorBrand: {
    childrenParams: {},
    filterParams: {}
  },
  sellerBrand: {
    childrenParams: {},
    filterParams: {}
  }
})

const actions = {}

export default {
  state,
  actions,
  persist: {
    enabled: true,
    isCommon: false,
    isLogoutClearStorage: false,
    isModuleStorage: true,
    watchFields: ['vendorBrand', 'sellerBrand']
  }
}
