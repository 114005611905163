
const state = () => ({
  personalCache: {
    VendorQueryModel: {},
    SellerQueryModel: {}
  }
})
const actions = {}
export default {
  state,
  actions,
  persist: {
    enabled: true,
    isCommon: false,
    isModuleStorage: true,
    watchFields: ['personalCache'],
    isLogoutClearStorage: false
  }
}
