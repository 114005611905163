import CurrencySymbol from '@commerceCommon/const/CurrencySymbol'
import { GetAdSpApiNotInCommerceCountApi } from '@!/api/Account/Authorization.js'
import { getIsTopTen } from './util'
import { getParseMapByFormatType } from '@commerceCommon/utils/dateFormat'
import { state as commonState, actions as commonActions, getters as commonGetters } from '@pacvue/store/user'

const state = () => {
  return {
    ...commonState(),
    spApiNotInCommerceCount: 0
  }
}

const newVersionSalesImpactAnalysisGroupMClientIdList = [
  // US & EU 728
  // US
  62, 106, 318, 515, 551, 611, 623, 684, 707,
  711, 728, 744, 790, 838, 857, 866, 877, 878,
  914, 938, 943, 955, 971, 984, 1004, 1030, 1042, 1046, 1103,
  1135, 1167, 1174, 1182, 1247, 1350, 1387, 1404, 1428, 1474,
  1634, 1800, 1848, 1877, 2007, 2095, 2210, 2313, 2527, 2568, 2571,
  2749, 2954, 2995, 3140, 3345, 3358, 3380, 3433, 3472, 3474,
  3484, 3623, 3624, 3625, 3642, 3927, 3940,
  // EU
  200035, 200044, 200059, 210062, 210184, 210343, 210454, 210492, 210549, 210556,
  210558, 210572, 210581, 210596, 210598, 210602, 210627, 210629
]

const getters = {
  ...commonGetters,
  isTenLevel(state) {
    return getIsTopTen(state.user?.clientId)
  },
  isNewVersionGroupM(state) {
    return [
      ...newVersionSalesImpactAnalysisGroupMClientIdList
    ].includes(state.user?.clientId)
  },
  defaultMoneySymbol(state) {
    return CurrencySymbol[state.defaultCountryCode] || '$'
  },
  isTestAdminUser(state) {
    return state.user?.clientId === 62
  },
  // 是否展示Content Authority
  isShowContentAuthority(state) {
    return !!state.userSettings?.featureList?.includes('ContentAuthority')
  },
  dateFormatObject(state) {
    const dateFormatType = state.userSettings?.dateFormat || '0'
    const parseMap = getParseMapByFormatType(dateFormatType)
    return { ...parseMap }
  }
}

const actions = {
  ...commonActions,
  SET_USERCURRENCY(currency) {
    this.userSettings.defaultCurrency = currency
    this.user.defaultCurrency = currency
    sessionStorage.setItem('setting', JSON.stringify(this.userSettings))
  },
  setSpApiNotInCommerceCount(count) {
    this.spApiNotInCommerceCount = count
  },
  getSpApiNotInCommerceCount() {
    return new Promise((resolve) => {
      GetAdSpApiNotInCommerceCountApi()
        .then((data) => {
          this.setSpApiNotInCommerceCount(data)
          resolve(data)
        })
        .catch(() => {
          resolve(0)
        })
    })
  }
}

export default {
  state,
  getters,
  actions
}
