<template>
  <div class="fixed" style="top: 100px; left: 100px; z-index: 9999 !important">
    <!-- <PacvueButton class="mt-4" type="primary" @click="clearGuideCache('executiveDashboardGuide')">clear HQ Guide</PacvueButton>
      <PacvueButton class="mt-4" type="success" @click="clearGuideCache('recommedationsMenuGuide')">clear Menu Guide</PacvueButton>
      <PacvueButton class="mt-4" type="danger" @click="clearGuideCache('shareOfVoiceAndIntelligenceMenuGuide')">clear Nav Guide</PacvueButton> -->
  </div>
</template>
<script setup>
import { app } from "../../../frame"
import { createApp, onBeforeUnmount } from "vue"
import { PacvueMessage } from "@pacvue/element-plus"
import config from "@~/config.js"
import { isWhiteLabel, judgeShare, isGroupM } from "../../../../constants/env"
import { useAppStore, useUserStore } from "@/store"
import RecommendationsGuide from "./components/RecommendationsGuide.vue"
import HQGuide from "./components/HQGuide.vue"
import ShareOfVoiceMenu from "./components/ShareOfVoiceMenu.vue"
import IntelligenceMenu from "./components/IntelligenceMenu.vue"
import SvgList from "@/components/SvgIcon/svgList"
import SvgIcon from "@main/components/SvgIcon/index.vue"

const appStore = useAppStore()
const userStore = useUserStore()
// 控制器
const guideManager = app.config.globalProperties.$GuideManager
const productLine = window.productline || localStorage.getItem("productline")

function initGuideManager() {
  let guideList = [guideHq(), guideNavigationUpdate(), guideRecommendation()]
  guideList.filter(Boolean).forEach((guide) => guideManager.addGuide(guide))
  guideManager.start()
  guideManager.onFinish(() => {
    console.log("引导结束了！")
  })
}

// 调试用
function clearGuideCache(e) {
  let { cacheKey } = getCacheKey(e)
  guideManager.clearRecords(cacheKey)
  PacvueMessage({
    message: $t("success"),
    type: "success"
  })
}

function generateVueAppInstance({ cpn, targetEl }) {
  const instance = createApp(cpn)
  instance.component("SvgIcon", SvgIcon)
  instance.component("PacvueIconPacvueStarLine", SvgList.PacvueIconPacvueStarLine)
  instance.mount(targetEl)
  return instance
}

// 顶部HQ ExecutiveDashboard引导
function guideHq() {
  // 未配置retailer时不显示引导
  if (!config.retailer) return false

  const { cacheKey, hasSeenGuide } = getCacheKey("executiveDashboardGuide")
  if (hasSeenGuide) {
    return false
  }

  // 是否有Executive Dashboard权限
  const availablePlatforms = userStore?.user?.availablePlatforms || []
  const hasRetailer = availablePlatforms.includes("retailer")
  const isInHeadquarter = ["retailer"].includes(productLine)
  const menuData = JSON.parse(sessionStorage.getItem("Menu-HQ"))
  const executiveHubMenu = menuData?.find((d) => d.alias === "Executive Hub")
  let hasExecutiveDashboard = executiveHubMenu?.children.some((d) => d.alias === "Executive Dashboard")
  if (!hasExecutiveDashboard || !hasRetailer || isInHeadquarter) {
    return false
  }

  const guideId = "#Pacvue-HQ-new"
  const appShadowList = []
  const defineSteps = [
    {
      element: guideId,
      popover: {
        title: "",
        createDescription({ el } = {}) {
          const appInstance = generateVueAppInstance({ cpn: HQGuide, targetEl: el })
          appShadowList.push(appInstance)
          // mask.unmount()
        },
        position: "bottom"
      }
    }
  ]
  const guideHandler = {
    steps: defineSteps,
    autoStart: false,
    classList: ["pacvue-hqGuideContent-wrapper"],
    extraOptions: {
      maxWidth: "initial",
      isRequireTitle: false,
      offset: 0,
      offsetX: 5,
      noPadding: true,
      onStepBtnChange(options = {}) {
        let reset = options.reset
        appShadowList.forEach((app) => {
          app.unmount()
        })
        if (options.isLast) {
          // el.classList.remove(className)
          let stageDom = document.getElementById("driver-highlighted-element-stage")
          if (stageDom) {
            stageDom.classList.remove("border-radius-12")
          }
          reset && reset(true)
          guideManager.showNext()
        }
      }
    }
  }
  return {
    key: cacheKey,
    weight: 3,
    content: guideHandler,
    isAsync: true,
    condition: async () => {
      return await new Promise((resolve) => {
        const preliminaryCondition = !judgeShare() && (!isWhiteLabel || isGroupM)
        resolve(preliminaryCondition)
      })
    }
  }
}
// 左侧菜单更新引导
function guideNavigationUpdate() {
  if (productLine !== "amazon") {
    return false
  }
  const appShadowList = []
  const guideId = "#pacvue-siderMenu_CA_Competitive" // 测试Share of Voice
  const guideId2 = "#pacvue-siderMenu_platformIntelligence"
  const hasMenu1Access = Object.keys(appStore.menuMap).some((key) => key.includes("/Analysis/"))
  const hasMenu2Access = Object.keys(appStore.menuMap).some((key) => key.includes("/CategoryIntelligence/"))
  const { cacheKey, hasSeenGuide } = getCacheKey("shareOfVoiceAndIntelligenceMenuGuide")
  // 如果没有菜单权限或已经引导
  if ((!hasMenu1Access && !hasMenu2Access) || hasSeenGuide) {
    return false
  }
  const guideDom = document.querySelector(guideId)
  const guideDom2 = document.querySelector(guideId2)
  let defineSteps = []
  if (hasMenu1Access && guideDom) {
    defineSteps.push({
      element: guideId,
      popover: {
        title: "",
        createDescription({ el } = {}) {
          const appInstance = generateVueAppInstance({ cpn: ShareOfVoiceMenu, targetEl: el })
          appShadowList.push(appInstance)
          // mask.unmount()
        },
        position: "right-top"
      }
    })
  }
  if (hasMenu2Access && guideDom2) {
    defineSteps.push({
      element: guideId2,
      popover: {
        title: "",
        createDescription({ el } = {}) {
          const appInstance = generateVueAppInstance({ cpn: IntelligenceMenu, targetEl: el })
          appShadowList.push(appInstance)
          // mask.unmount()
        },
        position: "right-top"
      }
    })
  }

  const guideHandler = {
    steps: defineSteps,
    autoStart: false,
    classList: ["pacvue-menuGuideContent-wrapper"],
    extraOptions: {
      maxWidth: "initial",
      isRequireTitle: false,
      offset: -60,
      offsetX: 8,
      noPadding: true,
      onStepBtnChange(options = {}) {
        let reset = options.reset
        appShadowList.forEach((app) => {
          app.unmount()
        })
        guideDom?.classList.remove("pacvue-menu-guide-step")
        guideDom?.classList.remove("pacvue-menu-guide-step-menu1")
        guideDom2?.classList.add("pacvue-menu-guide-step")
        if (options.isLast) {
          guideDom?.classList.remove("pacvue-menu-guide-step")
          guideDom?.classList.remove("pacvue-menu-guide-step-menu1")
          guideDom2?.classList.remove("pacvue-menu-guide-step")
          let stageDom = document.getElementById("driver-highlighted-element-stage")
          if (stageDom) {
            stageDom.classList.remove("border-radius-12")
          }
          reset && reset(true)
          guideManager.showNext()
        }
      }
    }
  }
  return {
    key: cacheKey,
    weight: 2,
    beforeStart: () => {
      if (guideDom) {
        guideDom.classList.add("pacvue-menu-guide-step")
        guideDom.classList.add("pacvue-menu-guide-step-menu1")
      }
    },
    content: guideHandler,
    condition: () => {
      return true
    },
    expireDate: "2025-04-10" // 过期时间
  }
}
// 左侧菜单Recommendation引导
function guideRecommendation() {
  const appShadowList = []
  const guideId = "#pacvue-siderMenu_Optimization"
  const hasMenuAccess = appStore.menuMap["/recommendation"]
  const { cacheKey, hasSeenGuide } = getCacheKey("recommedationsMenuGuide")
  // 如果没有菜单权限或已经引导
  if (!hasMenuAccess || hasSeenGuide) {
    return false
  }
  const defineSteps = [
    {
      element: guideId,
      popover: {
        title: "",
        createDescription({ el } = {}) {
          const appInstance = generateVueAppInstance({ cpn: RecommendationsGuide, targetEl: el })
          appShadowList.push(appInstance)
          // mask.unmount()
        },
        position: "right-top"
      }
    }
  ]
  const guideDom = document.querySelector("#pacvue-siderMenu_Optimization")
  const guideHandler = {
    steps: defineSteps,
    autoStart: false,
    classList: ["pacvue-menuGuideContent-wrapper"],
    extraOptions: {
      maxWidth: "initial",
      isRequireTitle: false,
      offset: -60,
      offsetX: 5,
      noPadding: true,
      onStepBtnChange(options = {}) {
        let reset = options.reset
        appShadowList.forEach((app) => {
          app.unmount()
        })
        if (options.isLast) {
          guideDom?.classList.remove("pacvue-menu-guide-step")
          let stageDom = document.getElementById("driver-highlighted-element-stage")
          if (stageDom) {
            stageDom.classList.remove("border-radius-12")
          }
          reset && reset(true)
          guideManager.showNext()
        }
      }
    }
  }
  return {
    key: cacheKey,
    weight: 1,
    beforeStart: () => {
      if (guideDom) {
        guideDom.classList.add("pacvue-menu-guide-step")
      }
    },
    content: guideHandler,
    condition: () => {
      return true
    }
  }
}

// 获取key和是否已经引导
function getCacheKey(key) {
  const userId = userStore?.user?.userId
  const cacheKey = key ? `_per_store_${userId}_${key}` : null
  return {
    cacheKey,
    hasSeenGuide: cacheKey ? localStorage.getItem(cacheKey) : null
  }
}
onBeforeUnmount(() => {
  app.config.globalProperties.$eventHub.off("hqOnMounted")
})
defineExpose({ initGuideManager })
</script>
<style lang="scss">
@import "./css/index.scss";
</style>
<style scoped lang="scss"></style>
