
const state = () => ({
  option: {
    Vendor: {},
    Seller: {}
  },
  queryModel: {
    Vendor: {},
    Seller: {}
  }
})
const actions = {

}
export default {
  state,
  actions,
  persist: {
    enabled: true,
    isCommon: false,
    isModuleStorage: true,
    watchFields: ['option', 'queryModel'],
    isLogoutClearStorage: false
  }
}
