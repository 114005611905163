import { createApp, provide } from "vue"
import "@main/index.css"
import "../store/storage-enhancer.js"
import "../store/cache-restore.js"
import { commonJS, pacvueToken } from "@pacvue/utils"
//import { createPinia } from 'pinia'
import createPinia from "@/store"
// import './permission'
import { i18n, $wt } from "./i18nEntry"
import _axios from "./authRequest"
import request from "./request"
import mitt from "mitt"
import SvgIcon from "@main/components/SvgIcon/index.vue" // svg组件
import SvgList from "@/components/SvgIcon/svgList.js" // svg组件
import "virtual:svg-icons-register"
// import "@main/assets/font/iconfont.css"
import "./theme.scss"
import { PacvueElement, LOCALE_ZHCN, LOCALE_EN } from "@pacvue/element-plus"
import LOCALE_JA from "@pacvue/element-plus/dist/locale/ja"
import "@pacvue/element-plus/dist/style.css"
import PacvuePermission from "@main/utils/PacvuePermission"
import { permission, tooltips, collect } from "./directives"
import App from "./App.vue"
import { GuideManager } from "../utils/guide.js"
import "./public-path"
import { isSpWhiteLabel, spWhiteLabelConfig } from "../constants/env.js"
import { OktaAuth } from "@okta/okta-auth-js"
import OktaVue from "@okta/okta-vue"
import sampleConfig from "@pacvue/frame/oktaConfig"

const oktaAuth = new OktaAuth(sampleConfig.oidc)

if (window.location.href.indexOf("ShowI18n") !== -1) {
  window.isShowI18n = true
}
// 默认先设置 theme2 主题
document.getElementsByTagName("body")[0].setAttribute("class", "theme2")

class InitAppClass {
  constructor() {
    this.routerInterceptors = {}
    this.shareLinkRouterInterceptors = {}
    this.userRelevantInterceptors = {}
    this.allRouterInterceptors = {}
    this.moduleRouter = []
    this.settingInterceptors = {}
    this.loginInterceptors = []
    this.pathPreprocessor = null
    this.app = createApp(App)
    // 使用 provide/inject 或全局属性挂载 oktaAuth
    this.app.provide("$auth", oktaAuth)
    const emitter = mitt()
    const localeMap = {
      ZH: LOCALE_ZHCN,
      EN: LOCALE_EN,
      JA: LOCALE_JA
    }
    this.app.use(PacvueElement, {
      locale: localeMap[localStorage.getItem("locale")],
      ...(isSpWhiteLabel ? { themeColors: spWhiteLabelConfig.themeColors } : {})
    })
    this.app.use(i18n)
    this.app.use(PacvuePermission)
    this.app.directive("permission", permission)
    this.app.directive("tooltips", tooltips)
    this.app.directive("collect", collect)

    this.app.component("SvgIcon", SvgIcon)

    // todo 图标svg 与 @element-plus/icons-vue有冲突
    for (const [key, component] of Object.entries(SvgList)) {
      this.app.component(key, component)
    }
    const setChannel = new BroadcastChannel("userSettingCHANGE")
    this.app.config.globalProperties.$wt = $wt
    this.app.config.globalProperties.$channel = setChannel
    this.app.config.globalProperties.$eventHub = emitter
    this.app.config.globalProperties.$commonJS = commonJS
    this.app.config.globalProperties.$axios = _axios
    this.app.config.globalProperties.$GuideManager = new GuideManager()
    this.app.config.errorHandler = (err, instance, info) => {
      console.error(err, instance, info)
      // console.log(`%c ${err} %O ${info}`, 'color: orange; font-weight: bold; font-size: 20px', instance)
    }
    // app.config.globalProperties.$request = request
    this.app.config.globalProperties.pacvueToken = pacvueToken
    // 执行所有需要在resize中执行的函数
    window.onresize = () => {
      let pubMap = this.app.config.globalProperties.$eventHub.all
      pubMap.forEach((value, key, map) => {
        if (key.substring(0, 7) === "resize-") {
          this.app.config.globalProperties.$eventHub.emit(key)
        }
      })
    }
    setChannel.onmessage = function (e) {
      const setting = sessionStorage.getItem("setting")
      if (setting) {
        const userId = JSON.parse(setting).userId
        if (userId && userId == e.data.userId) {
          sessionStorage.setItem("setting", JSON.stringify(e.data))
        }
      }
    }
  }
  /**
   * 处理拦截器, 并率先确定相互协作的同步异步执行顺序, 且支持链式调用
   * @param { * } plugin 需要扩展的插件模块
   * @param { String } key 拦截器名称
   */
  handleInterceptors(plugin, key) {
    if (plugin[key] && Array.isArray(plugin[key])) {
      // interceptor: () => thenable
      for (const interceptor of plugin[key]) {
        const { syncSeq } = interceptor()
        if (!this[key][`phase${syncSeq}`]) {
          this[key][`phase${syncSeq}`] = []
        }
        this[key][`phase${syncSeq}`].push(interceptor)
      }
    }
    return this
  }
  /**
   * 融合指定的拦截器
   */
  integrateRouterInterceptors() {
    const pluginNames = ["userRelevantInterceptors", "routerInterceptors"]
    for (const pluginName of pluginNames) {
      let i = 0
      while (`phase${i}` in this[pluginName]) {
        if (!this.allRouterInterceptors[`phase${i}`]) {
          this.allRouterInterceptors[`phase${i}`] = []
        }
        this.allRouterInterceptors[`phase${i}`].push(...this[pluginName][`phase${i}`])
        i++
      }
    }
  }
  extend(plugin) {
    this.handleInterceptors(plugin, "userRelevantInterceptors")
      .handleInterceptors(plugin, "settingInterceptors")
      .handleInterceptors(plugin, "routerInterceptors")
      .handleInterceptors(plugin, "shareLinkRouterInterceptors")
    plugin.loginInterceptors && this.loginInterceptors.push(plugin.loginInterceptors)
    plugin.pathPreprocessor && (this.pathPreprocessor = plugin.pathPreprocessor)
    if (plugin.router) {
      if (Array.isArray(plugin.router)) {
        this.moduleRouter = this.moduleRouter.concat(plugin.router)
      } else {
        this.moduleRouter.push(plugin.router)
      }
    }
  }
  init(router, dom, custPinia) {
    //TODO 创建vue的函数可以放在此处 处理未来的缓存问题
    if (custPinia) {
      this.app.use(custPinia())
    } else {
      this.app.use(createPinia())
    }
    this.app.use(router)
    this.app.use(OktaVue, { oktaAuth })
    this.app.mount(dom)
    // this.app.use(analyticsIdentify, { router })
    return this.app
  }
}
const InitApp = new InitAppClass()
const app = InitApp.app
export { InitApp, app, pacvueToken, _axios, request }
