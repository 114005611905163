
const state = () => ({
  vendorCategory: {
    childrenParams: {},
    filterParams: {}
  },
  sellerCategory: {
    childrenParams: {},
    filterParams: {}
  }
})

const actions = {}

export default {
  state,
  actions,
  persist: {
    enabled: true,
    isCommon: false,
    isLogoutClearStorage: false,
    isModuleStorage: true,
    watchFields: ['vendorCategory', 'sellerCategory']
  }
}
