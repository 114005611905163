
const state = () => ({
  sellerState: {
    calendarCurrentViewType: '',
    filterFormByPromotion: {},
    filterFormByProduct: {},
    storeForm: '' // object
  },
  vendorState: {
    calendarCurrentViewType: '',
    filterFormByPromotion: {},
    filterFormByProduct: {},
    storeForm: '' // object
  }
})

const actions = {

}

export default {
  state,
  actions,
  persist: {
    enabled: true,
    isCommon: false,
    isModuleStorage: true,
    isLogoutClearStorage: false,
    watchFields: ['sellerState', 'vendorState']
  }
}
