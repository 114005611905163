const state = () => ({
  currentTabCache: '',
  filterCache: {},
  cachesByTab: {
    QueryModelTab1: {},
    QueryModelTab2: {}
  }
})

const actions = {}

export default {
  state,
  actions,
  persist: {
    enabled: true,
    isCommon: false,
    isModuleStorage: true,
    watchFields: ['currentTabCache', 'filterCache', 'cachesByTab'],
    isLogoutClearStorage: false
  }
}
