import { useAppStore, useAsinDetailStore, useCommonStore, useDriverAlertStore, useDriverJBPStore, useDriverStore, useUserStore } from '@!/store'
import { getCommerceSettings } from '@!/api/Account/settings.js'
import * as storeModule from '@!/store/modules/index.js'
import useDatePickerOptionsInit from '@!/utils/useDatePickerOptionsInit'
import { initWebSocket } from '@!/utils/websocket.js'
import { isEmpty } from '@commerceCommon/utils/common.js'
import { setCompareCustom } from '@commerceCommon/utils/storeUtil.js'
import { PacvueMessage } from '@pacvue/element-plus'
import { decodeBasicUserInfoByToken } from '@pacvue/frame/authUtils'
import { useAnalyticsPageWithRouterName } from '@pacvue/frame/const'
import { i18n } from '@pacvue/frame/i18nEntry'
import { judgeClientAndRedirect } from '@pacvue/frame/navUtils'
import { watch } from 'vue'

export const PAGE_TYPE_MAPPER = {
  DisputeOverview: '1p',
  // AlertHistory: '3p',
  Dispute: '1p',
  Payment: '1p',
  Deduction: '1p',
  Claim: '3p',
  ClaimHistory: '3p',
  Repricer: '3p',
  Order: '3p',
  KeywordAnalysis: '1p&3p',
  ShareOfVoice: '1p&3p',
  DefaultReport: '1p&3p',
  MyReport: '1p&3p',
  MailBox: '3p',
  AutomaticRule: '3p',
  TemplateManagement: '3p',
  PacvueTools: '3p',
  MyAccount: '1p&3p',
  UserManage: '1p&3p',
  Setting: '1p&3p',
  VariationTesting: '1p',
  ClaimOverview: '3p',
  APlusContent: undefined,
  addAPlus: undefined
  // Payment: undefined
}

let commonStore = null
export const getPageType = () => {
  if (!commonStore) {
    commonStore = useCommonStore()
  }
  return commonStore.commerceType === 'Vendor' ? { 'page business type': '1p' } : { 'page business type': '3p' }
}

// 初始化页面埋点 - store
export const useAnalyticsPageByStore = ({
  commonStore,
  router
}) => {
  // 监听 store 变化 - 埋点
  return watch(() => commonStore.commerceType,
    () => {
      const routeName = router.currentRoute.value.name
      useAnalyticsPageWithRouterName(routeName, getPageType())
    })
}

// 权限拦截
const usePermissionProxy = async({ to, appStore }) => {
  return new Promise((resolve, reject) => {
    const toPath = to.path
    const toMeta = to.meta
    const { menuMap } = appStore
    // 达成进入的条件
    // 1.在 menuMap 里面
    if (menuMap[ toPath ]) {
      return resolve(true)
    }
    // 2.配置忽略权限拦截为 true
    if (toMeta?.ignorePermissionProxy) {
      return resolve(true)
    }
    // 3.关联路由权限已打开
    // 没有配置的情况直接跳转
    const relationPermissionPaths = toMeta?.relationPermissionPaths
    if (isEmpty(relationPermissionPaths)) {
      if (window.permissionObj?.[to.name] === 0 || toMeta?.isNotPublished) {
        resolve(false)
      }
      return resolve(true)
    } else {
      let isNext
      for (let i = 0; i < relationPermissionPaths.length; i++) {
        const relationPermissionPath = relationPermissionPaths[i]
        if (menuMap[ relationPermissionPath ]) {
          isNext = true
          break
        }
      }
      return resolve(isNext)
    }
  })
}

let appStore = null
export default {
  async loginInterceptors(router) {
    try {
      // 登录前清空store，解决多tab不退出登录store串的问题
      for (const i in storeModule) {
        storeModule[i]()?.$reset()
      }
      const { useCommonStore, useUserStore, useAppStore, useDriverStore, useDriverAlertStore, useDriverJBPStore } = storeModule
      const commonStore = useCommonStore()
      const userStore = useUserStore()
      const appStore = useAppStore()
      const driverStore = useDriverStore()
      const driverAlertStore = useDriverAlertStore()
      const driverJBPStore = useDriverJBPStore()
      // 率先解码出 token 中的 userId
      decodeBasicUserInfoByToken({ userStoreContext: userStore })
      // 一些特殊大客户需要在登录后直接跳转至对应他们的白名单域名 [未来挪到 pacvue 包]
      const shouldRedirect = await judgeClientAndRedirect({ userStoreContext: userStore })
      if (shouldRedirect) return
      useDatePickerOptionsInit(appStore)
      // 此时已经拿到 uid, 可以执行 Persist 以设置上持久化存储数据
      commonStore.setUserPersist()
      driverStore.setUserPersist()
      driverAlertStore.setUserPersist()
      driverJBPStore.setUserPersist()
      // WARNING: 上述代码都应该迁移至路由中处理 sso不会走这个逻辑
      // WARNING: 下述代码只是为了暂时满足commerce做的特殊处理，未来依然需要后端的配合改造才能完
      // && window.location.host.includes('commerce')
      // if (userStore.user.availablePlatforms.includes('commerce-walmart') && !userStore.user.availablePlatforms.includes('commerce') && window.location.host.includes('commerce')) {
      //   window.open(`${window.location.protocol}//${window.location.host}/commerce-walmart`, '_self')
      //   return
      // }
      // 登录后建立websocket连接
      if (!window.commerceWebSocket) {
        initWebSocket()
      }
      router.push('/')
    } catch (error) {
      PacvueMessage({
        message: error.message || 'Oops! Something went wrong on the server and we couldn\'t process your request.',
        type: 'error',
        customClass: 'pacvue-message-error'
      })
    }
  },
  routerInterceptors: [
    () => ({
      syncSeq: 0,
      async then(onFulfill, onReject) {
        try {
          const commonStore = useCommonStore()
          const driverStore = useDriverStore()
          const driverAlertStore = useDriverAlertStore()
          const driverJBPStore = useDriverJBPStore()
          commonStore.setUserPersist()
          driverStore.setUserPersist()
          driverAlertStore.setUserPersist()
          driverJBPStore.setUserPersist()
          await Promise.allSettled([
            commonStore.getUserPermission(),
            commonStore.getVendorOrSellerIsSnsData({ withCancel: false }),
            commonStore.getVendorOrSellerSnsIds({ withCancel: false }),
            commonStore.getManualCasePermission(),
            commonStore.getManagedByPacvueImp({ withCancel: false }),
            commonStore.getAlertInventoryMonitor({ withCancel: false })
          ])
          onFulfill(true)
        } catch (err) {
          onReject(err)
        }
      }
    }),
    // commerce 偏好
    () => ({
      syncSeq: 0,
      async then(onFulfill, onReject) {
        try {
          if (!sessionStorage.getItem('platformPrefSetting')) {
            const commerceSettings = await getCommerceSettings()
            sessionStorage.setItem('platformPrefSetting', JSON.stringify(commerceSettings))
          }
          onFulfill(true)
        } catch (err) {
          onReject(err)
        }
      }
    }),
    // 偏好及国际化追加逻辑
    () => ({
      syncSeq: 1,
      async then(onFulfill, onReject) {
        try {
          const appStore = useAppStore()
          const userStore = useUserStore()
          const commonStore = useCommonStore()
          const asinDetailStore = useAsinDetailStore()
          if (userStore.isDemo) {
            appStore.SET_DATERANGE({
              startDate: '02/01/2023',
              endDate: '02/28/2023'
            })
          }
          commonStore.mountCustomHeader()
          // 同步 asinDetail openType by userStore
          asinDetailStore.setOpenAsinDetailTypeByArrEnum(userStore.userSettings.asinStatusDetail, false)
          // 国际化处理
          let i8 = 'EN'
          let settingConfig = null
          if (sessionStorage.getItem('setting')) {
            settingConfig = JSON.parse(sessionStorage.getItem('setting'))
            i8 = settingConfig.language
          } else {
            settingConfig = userStore.userSettings
            i8 = settingConfig.language
          }
          if (i8 !== localStorage.getItem('locale')) {
            window.prefLangDiffered = true
            localStorage.setItem('locale', i8)
          }
          if (i18n.mode === 'legacy') {
            i18n.global.locale = i8
          } else {
            i18n.global.locale.value = i8
          }
          let elementLocale = 'en'
          if (localStorage.getItem('locale') === 'ZH') {
            elementLocale = 'zh-cn'
          } else if (localStorage.getItem('locale') === 'JA') {
            elementLocale = 'ja'
          }
          if (window.__inject_pacvue_element_changeLocale__) {
            window.__inject_pacvue_element_changeLocale__({
              option: { locale: { name: elementLocale }}
            })
          }
          // 通用设置拼接平台特有偏好设置
          if (settingConfig) {
            const commerceSettings = JSON.parse(sessionStorage.getItem('platformPrefSetting') || '{}')
            userStore.SET_USERSETTINGS({
              ...settingConfig,
              defaultCurrency: ['BE', 'DE', 'ES', 'FR', 'IT', 'NL'].includes(settingConfig.defaultCurrency) ? 'DE' : settingConfig.defaultCurrency,
              dateFormat: '0',
              commerceSettings,
              asinStatus: !!commerceSettings.asinStatusSwitch,
              language: settingConfig.language || 'EN'
            })
          }
          onFulfill(true)
        } catch (err) {
          onReject(err)
        }
      }
    }),
    // 最终权限拦截
    (to, from, next) => ({
      syncSeq: 2,
      async then(onFulfill, onReject) {
        try {
          if (!appStore) {
            appStore = useAppStore()
          }
          const commonStore = useCommonStore()
          const excludePaths = ['/Account/MyAccount', '/Guide', '/Binding']
          if (isEmpty(commonStore.channelList) && !excludePaths.includes(to.path)) {
            next('/Guide')
          }
          if (!isEmpty(commonStore.channelList) && to.path === '/Guide') {
            next(window.firstRouter)
          }
          // Asin Time 页面跳转时判断是否是PoP模式，是则设置日期组件自定义比较
          if (to.meta?.showCompareCheck && appStore.isComparePop) {
            setCompareCustom()
          }
          // 路由权限管理
          const isNext = await usePermissionProxy({ to, appStore })
          // 不在权限管理直接重定向到 homepage 或第一个具有权限的路径
          if (!isNext && !window.inNextGuardLoops) {
            next(appStore.menuMap[window.HomePage] ? window.HomePage : window.firstRouter)
          }
          onFulfill(true)
        } catch (err) {
          onReject(err)
        }
      }
    })
  ]
}
