const state = () => ({
  queryModel: {},
  topCard: {},
  childParams: {}
})
const actions = {

}

export default {
  state,
  actions,
  persist: {
    enabled: true,
    isCommon: false,
    isModuleStorage: true,
    isLogoutClearStorage: false,
    watchFields: ['queryModel', 'topCard', 'childParams']
  }
}
