
const state = () => ({
  alertHistoryBy1P: {
    filterCache: {}
  },
  alertHistoryBy3P: {
    filterCache: {}
  }
})

const actions = {
}

export default {
  state,
  actions,
  persist: {
    enabled: true,
    isCommon: false,
    isLogoutClearStorage: false,
    isModuleStorage: true,
    watchFields: ['alertHistoryBy1P', 'alertHistoryBy3P']
  }
}
