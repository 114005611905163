
const state = () => ({
  sellerState: {
    filterForm: '', // object
    toolForm: '' // object
  }
})

const actions = {

}

export default {
  state,
  actions,
  persist: {
    enabled: true,
    isCommon: false,
    isModuleStorage: true,
    isLogoutClearStorage: false,
    watchFields: ['sellerState']
  }
}
