import { ManagedByPacvueServerStatusMenu } from '@/const/ManagedByPacvueServerStatusMenu'

export default function() {
  return {
    platform: localStorage.getItem('productline') || 'commerce',
    commercePlatform: 'amazon',
    commerceType: 'Vendor',
    isAllAsyncDataComplete: true,
    marketStore: {},
    categoryStore: {},
    brandStore: {},
    originCategoryStore: {},
    originBrandStore: {},
    customBrandStore: {},
    customCategoryStore: {},
    productTagStore: {},
    tagOwnerList: [],
    channelList: [],
    showTax: false,
    overviewJumpHistoryMap: {
      vendor: false
      // seller: false // 3p目前还没做新版,先注掉
    },
    checkedFinancesFormula: [],
    sortPanelStatus: false, // 控制下拉排序面板状态
    dashboardArray: [], // 面板数据状态
    otherDashboardArray: [], // 其他面板数据状态
    profileList: [], // list
    profileIds: [], // id集合
    campaignTags: [], // tags集合
    portfolio: [], // portfolio集合
    analyticsProfileId: [],
    advertiserIds: [],
    attrAdvertiserIds: [],
    sharePreference: 'ACOS',
    showContentPer: [],
    showContentEff: [],
    // 缓存ASDIN Detail ->Alert
    problemTypeValueMap: {
      seller: [],
      vendor: []
    },
    // 缓存 全局 market store - seller
    marketStoreValueMap: {
      seller: {
        market: [],
        store: []
      },
      vendor: {
        market: [],
        store: []
      }
    },
    brandCategoryValueMap: {
      seller: {
        brand: [],
        brandList: [],
        category: [],
        categoryList: []
      },
      vendor: {
        brand: [],
        brandList: [],
        category: [],
        categoryList: []
      }
    },
    originBrandCategoryValueMap: {
      seller: {
        brand: [],
        brandList: [],
        category: [],
        categoryList: []
      },
      vendor: {
        brand: [],
        brandList: [],
        category: [],
        categoryList: []
      }
    },
    customBrandCategoryValueMap: {
      seller: {
        brand: [],
        brandList: [],
        category: [],
        categoryList: []
      },
      vendor: {
        brand: [],
        brandList: [],
        category: [],
        categoryList: []
      }
    },
    distributorAndSellingMap: {
      distributor: 'Manufacturing',
      selling: 'Retail'
    },
    repSuggestionType: '4', // 这个需要存起来
    tagOwnerValueMap: {
      seller: {
        tagOwner: []
      },
      vendor: {
        tagOwner: []
      }
    },
    tagValueMap: {
      seller: {
        tagIds: [],
        tagCondition: 0
      },
      vendor: {
        tagIds: [],
        tagCondition: 0
      }
    },
    isSmallScreen: document.getElementById('vm').clientWidth < 1600,
    salesHourlyType: 'Trend',
    // 账户是否有 sns 数据
    hasSnsData: {
      vendor: null,
      seller: null
    },
    /**
     * Pacvue托管服务状态
     * failed 接口失败，页面需要重新拉取状态
     * yse 开启
     * no 没有开启
     */
    managedByPacvue: ManagedByPacvueServerStatusMenu.FAILED,
    inventoryMonitorMap: {
      isAsinLevel: null,
      range: null
    },
    // 细化到账户有SnS数据的StoreId, string[]
    SnsIds: {
      vendor: null,
      seller: null
    },
    // Sales Panel 缓存
    salePanelMap: {
      seller: {
        cachePanelListObj: {
          realTime: [],
          sns: [],
          hourly: [],
          normal: []
        },
        cachePanelShowListObj: {
          realTime: [],
          sns: [],
          hourly: [],
          normal: []
        }
      },
      vendor: {
        cachePanelListObj: {
          ManufacturingFresh: [],
          ManufacturingCore: [],
          ManufacturingBusiness: [],
          SourcingRetail: [],
          SourcingFresh: [],
          SourcingCore: [],
          SourcingBusiness: [],
          sns: [],
          hourly: [],
          normal: []
        },
        cachePanelShowListObj: {
          ManufacturingFresh: [],
          ManufacturingCore: [],
          ManufacturingBusiness: [],
          SourcingRetail: [],
          SourcingFresh: [],
          SourcingCore: [],
          SourcingBusiness: [],
          sns: [],
          hourly: [],
          normal: []
        }
      }
    },
    realTimeSalePanelMap: {
      vendor: {
        cachePanelList: [],
        cachePanelShowList: []
      },
      seller: {
        cachePanelList: [],
        cachePanelShowList: []
      }
    },
    // vendorCode 缓存的值
    vendorCodeValue: [],
    // 是否可以手动开case
    canManualCase: null,
    // 是否隐藏自定义HeaderBar
    isHideHeaderBar: false,
    // 下载唯一标识列表，用于自动下载
    downloadTaskIdList: [],
    // target 日期缓存
    targetDateRange: {
      startDate: '',
      endDate: ''
    },
    // 是否为coop user
    isCoOpUser: -1
  }
}
