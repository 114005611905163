
const state = () => ({
  storeType: [],
  isFullNumber: false
})
const actions = {

}
export default {
  state,
  actions,
  persist: {
    enabled: true,
    isCommon: false,
    isModuleStorage: true,
    watchFields: ['storeType', 'isFullNumber'],
    isLogoutClearStorage: false
  }
}
