
const state = () => ({
  sellerState: {
    breadFilterForm: {},
    reservedTrend: {
      chartSelectAxis: '', // array
      timeType: ''
    },
    returnTrend: {
      timeType: ''
    },
    unfulfillableTrend: {
      chartSelectAxis: '', // array
      timeType: ''
    },
    monthlyStorageFeeTrend: {
      tab: ''
    }
  },
  vendorState: {
    breadFilterForm: {},
    purchaseOrder: {
      totalSwitch: ''
    },
    warehousePurchaseOrderBubble: {
      switchType: '',
      metricChoose: ''
    },
    inventoryAndPOPerformance: {
      switchType: ''
    },
    weeksOfCoverTrend: {
      switchDim: ''
    },
    orderFillRateTrend: {
      switchDim: ''
    },
    inventory: {
      switchDim: ''
    },
    summaryOfChargebacks: {
      history: '', // boolean
      statusChose: '' // array
    },
    unhealthyInventory: {
      switchType: ''
    },
    inventoryEfficiency: {
      chartSetting: '', // object
      showTop: '' // boolean
    },
    lostSalesDueToRejectedPO: {
      targetChose: '',
      switchDim: ''
    }

  }
})

const actions = {

}

export default {
  state,
  actions,
  persist: {
    enabled: true,
    isCommon: false,
    isModuleStorage: true,
    isLogoutClearStorage: false,
    watchFields: ['sellerState', 'vendorState']
  }
}
