const getCommonState = () => {
  return {
    breadFilterForm: {},
    globalParams: '', // object
    reviewsAndCriticalReviews: {
      metricList: '' // array
    },
    ratingTrend: {
      metricList: '' // array
    },
    tagAllocation: {
      chartType: ''
    },
    performanceTable: {
      activeTab: ''
    }
  }
}
const state = () => ({
  sellerState: getCommonState(),
  vendorState: getCommonState()
})

const actions = {

}

export default {
  state,
  actions,
  persist: {
    enabled: true,
    isCommon: false,
    isModuleStorage: true,
    isLogoutClearStorage: false,
    watchFields: ['sellerState', 'vendorState']
  }
}
