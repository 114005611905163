
const state = () => ({
  sellerState: {
    // 视图类型 - 国家 or 城市
    viewType: '',
    breadcrumbFilter: '',
    globalViewFilterForm: {
      // // 排序字段类型
      // viewFieldType: '',
      // // 地区展示类型
      // countryRegionShowType: '',
      // // 自定义国家
      // customCountry: []
    },
    regionalFilterForm: {
      // // 排序字段类型
      // viewFieldType: '',
      // // 地区展示类型
      // countryRegionShowType: '',
      // // 自定义城市
      // customRegionMap: { UnitedStates:[] }
    }
  },
  vendorState: {
    // 视图类型 - 国家 or 城市
    viewType: '',
    breadcrumbFilter: '',
    globalViewFilterForm: {
      // // 排序字段类型
      // viewFieldType: '',
      // // 地区展示类型
      // countryRegionShowType: '',
      // // 自定义国家
      // customCountry: []
    },
    regionalFilterForm: {
      // // 排序字段类型
      // viewFieldType: '',
      // // 地区展示类型
      // countryRegionShowType: '',
      // // 自定义城市
      // customRegionMap: { UnitedStates:[] }
    }
  }
})

const actions = {

}

export default {
  state,
  actions,
  persist: {
    enabled: true,
    isCommon: false,
    isModuleStorage: true,
    isLogoutClearStorage: false,
    watchFields: ['sellerState', 'vendorState']
  }
}
