export const VendorHomeDragConfig = {
  ST: {
    title: 'Order Performance',
    component: 'OrderedPerformance'
  },
  ASSP: {
    title: 'Ad Supported Sales Performance',
    component: 'AdSupported'
  },
  TACP: {
    title: 'Traffic and Conversion Performance',
    component: 'TrafficCVRPerformance'
  },
  SCP: {
    title: 'Supply Chain Performance',
    component: 'SupplyChainPerformance'
  },
  BO: {
    title: 'Buybox Ownership Trend',
    component: 'BuyboxOwnership'
  },
  MP: {
    title: 'Margin Performance',
    component: 'MarginPerformance'
  },
  CTP: {
    title: 'Campaign Type Performance',
    component: 'CampaignTypePerformance'
  },
  PbY: {
    title: 'Ad Performance by Weekday',
    component: 'AdPerformanceByWeekday'
  },
  SHP: {
    title: 'Shipped Performance',
    component: 'ShippedPerformance'
  },
  SF: {
    title: 'Sales Forecast',
    component: 'SalesForecast'
  },
  TOP: {
    title: 'Top 10',
    component: 'TopTen'
  },
  AE: {
    title: 'ASIN Efficiency',
    component: 'ASINEfficiency'
  },
  HS: {
    title: 'Hourly Sales',
    component: 'HourlySales'
  },
  CR: {
    title: 'Customer Returns',
    component: 'CustomerReturns'
  },
  TTM: {
    title: 'Top 10 Movers',
    component: 'TopTenMovers'
  },
  GSD: {
    title: 'Geo-Sales Distribution',
    component: 'HomeBubbleMap'
  }
}
export const SellerHomeDragConfig = [
  {
    id: 'Sales Trend',
    checked: true, // 是否显示
    desc: 'Sales Trend', // 模块描述
    componentName: 'SalesTrendLineChart', // 组件名称
    refName: 'salesTrendRef' // 组件实例名称
  },
  {
    id: 'Ad Supported Sales Performance',
    checked: true,
    desc: 'Ad Supported Sales Performance',
    componentName: 'AdSupported',
    refName: 'adSupportedRef'
  },
  // {
  //   id: 'Advertising Trend',
  //   checked: true,
  //   desc: 'Advertising Trend',
  //   componentName: 'AdvertisingTrendLineChart',
  //   refName: 'advertisingTrendRef'
  // },
  {
    id: 'Top 10 Marketplaces',
    checked: true,
    desc: 'Top 10 Marketplaces',
    componentName: 'TopTenChart',
    refName: 'topTenRef'
  },
  {
    id: 'Hourly Orders',
    checked: true,
    desc: 'Hourly Orders',
    componentName: 'HourlyOrders',
    refName: 'hourlyOrdersRef'
  },
  {
    id: 'Account Health & Inventory Age',
    checked: true,
    desc: 'Account Health & Inventory Age',
    componentName: 'AccountAndInventory',
    noWrap: true
  },
  {
    id: 'Replenishment Advice',
    checked: true,
    desc: 'Replenishment Advice',
    componentName: 'ReplenishmentAdviceTable'
  },
  {
    id: 'Order Distribution Map',
    checked: true,
    desc: 'Order Distribution Map',
    componentName: 'HomeBubbleMap'
  },
  {
    id: 'Top 10 Movers',
    checked: true,
    desc: 'Top 10 Movers',
    componentName: 'TopTenMovers'
  }
]

export const VendorInventoryDragConfig = [
  { checked: true, title: 'Order Fill Rate Trend', component: 'OrderFillRateTrend' },
  { checked: true, title: 'Weeks of Cover Trend', component: 'WeeksOfCoverTrend' },
  { checked: true, title: 'Inventory Efficiency', component: 'InventoryEfficiency' },
  { checked: true, title: 'Predictive Forecast', component: 'PredictiveForecast' },
  { checked: true, title: 'Inventory', component: 'InventoryChart' },
  { checked: true, title: 'Different Types of Purchase Orders', component: 'LostSalesDueToRejectedPO' },
  { checked: true, title: 'Unhealthy Inventory', component: 'UnhealthyInventory' },
  { checked: true, title: 'Summary of Chargebacks', component: 'SummaryOfChargebacks' },
  { checked: true, title: 'Warehouse Purchase Order', component: 'WarehousePurchaseOrder' },
  { checked: true, title: 'Inventory And PO Performance', component: 'InventoryAndPOPerformance' }
]

export const SellerInventoryDragConfig = [
  { checked: true, title: 'Weeks of Cover Trend', component: 'WeeksofCoverTrend' },
  { checked: true, title: 'Reserved Trend', component: 'ReservedTrend' },
  { checked: true, title: 'Return Trend', component: 'ReturnTrend' },
  { checked: true, title: 'Unfulfillable Trend', component: 'UnfulfillableTrend' },
  { checked: true, title: 'Shipment Status', component: 'ShipmentStatus' },
  { checked: true, title: 'Inventory Age Status', component: 'InventoryAgeStatus' },
  { checked: true, title: 'Monthly Storage Fee Trend', component: 'MonthlyStorageFeeTrend' }
]

export const InventoryI18nNameConfig = () => ({
  'Order Fill Rate Trend': $t('inventoryVendor.orderFillRateTrend'),
  'Weeks of Cover Trend': $t('inventoryVendor.weeksOfCoverTrend'),
  'Inventory Efficiency': $t('inventoryVendor.inventoryEfficiency'),
  'Predictive Forecast': $t('inventoryVendor.predictiveForecast'),
  'Inventory': $t('inventoryVendor.inventory'),
  'Different Types of Purchase Orders': $t('inventoryVendor.differentTypesOfPurchaseOrders'),
  'Unhealthy Inventory': $t('inventoryVendor.unhealthyInventory'),
  'Summary of Chargebacks': $t('inventoryVendor.summaryOfChargebacks'),
  'Warehouse Purchase Order': $t('inventoryVendor.warehousePurchaseOrder'),
  'Reserved Trend': $t('inventory.WidgetLabel.ReservedTrend'),
  'Return Trend': $t('inventory.WidgetLabel.ReturnTrend'),
  'Unfulfillable Trend': $t('inventory.WidgetLabel.UnfulfillableTrend'),
  'Shipment Status': $t('claimDetail.shipmentStatus'),
  'Inventory Age Status': $t('inventory.WidgetLabel.InventoryAgeStatus'),
  'Monthly Storage Fee Trend': $t('CommerceAmazon.title.MonthlyStorageFeeTrend'),
  'Inventory And PO Performance': $t('inventoryVendor.InventoryAndPOPerformance')
})

const getVendorOrSellerInventory = (type) => {
  return (type === 'Vendor' ? VendorInventoryDragConfig : SellerInventoryDragConfig).map(item => {
    return {
      key: item.component,
      show: true,
      title: item.title
    }
  })
}
export const getDragConfig = {
  get VendorHome() {
    return Object.keys(VendorHomeDragConfig).map(item => {
      return {
        key: VendorHomeDragConfig[item].component,
        show: true,
        title: VendorHomeDragConfig[item].title
      }
    })
  },
  get SellerHome() {
    return SellerHomeDragConfig.map(item => {
      return {
        key: item.componentName,
        show: true,
        title: item.desc
      }
    })
  },
  get VendorInventory() {
    return getVendorOrSellerInventory('Vendor')
  },
  get SellerInventory() {
    return getVendorOrSellerInventory('Seller')
  }
}
