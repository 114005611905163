
const state = () => ({
  sellerState: {
    breadFilterForm: {},
    topWidget: {
      totalBuyBoxSwitch: ''
    },
    buyboxOwnershipByBrands: {
      brandType: '',
      initFormatProps: '' // object
    },
    top20MoversProducts: {
      initFormatProps: '' // object
    },
    top20SellersProducts: {
      initFormatProps: '' // object
    }
  },
  vendorState: {
    breadFilterForm: {},
    topTotal: {
      totalBuyBoxSwitch: ''
    },
    trendChart: {
      switchDim: ''
    },
    buyboxByBrands: {
      brandType: ''
    },
    top20Movers: {
      initFormatProps: '' // object
    },
    top20Sellers: {
      isFullNumber: ''
    }
  }
})

const actions = {

}

export default {
  state,
  actions,
  persist: {
    enabled: true,
    isCommon: false,
    isModuleStorage: true,
    isLogoutClearStorage: false,
    watchFields: ['sellerState', 'vendorState']
  }
}
