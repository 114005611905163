
const state = () => ({
  panelPlanListBy3p: [],
  panelPlanListBy1p: [],
  filterCache1p: {
    currentTab: '1',
    dateType: 'H',
    showChart: true,
    isFullNumber: false,
    hourlyType: 'Trend'
  },
  filterCache3p: {
    chartCache: {
      dateType: 'D',
      hourlyType: 'Trend'
    },
    currentTab: '1',
    dateType: 'D',
    showAsinWithoutOrders: true,
    showChart: true,
    isFullNumber: false
  },
  chartCache: {},
  panelCache1p: {},
  panelCache3p: {}
})

const actions = {}

export default {
  state,
  actions,
  persist: {
    enabled: true,
    isCommon: false,
    isLogoutClearStorage: false,
    isModuleStorage: true,
    watchFields: ['panelPlanListBy3p', 'panelPlanListBy1p', 'filterCache3p', 'filterCache1p', 'chartCache', 'panelCache1p', 'panelCache3p']
  }
}
