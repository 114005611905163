import { dayjs } from '@pacvue/element-plus'
import { isString, isFunction } from 'lodash-es'
import { useUserStore } from '@#/store'

/**
 * @description: 日期类型枚举
 */
export const DateTypeEnum = {
  Date: 'Date',
  DayDate: 'DayDate',
  TimeMDate: 'TimeMDate',
  TimeSDate: 'TimeSDate',
  WeekYear: 'WeekYear',
  WeekYearDate: 'WeekYearDate',
  MonthYear: 'MonthYear',
  QuarterYear: 'QuarterYear',
  Year: 'Year'
}

/**
 * @description: 格式化类型枚举
 */
export const FormatTypeEnum = {
  DMY: '1',
  MDY: '0',
  YMD: '2'
}

/**
 * @description: 日期格式化Map
 */
const DateFormatTypeParseMap = {
  [FormatTypeEnum.DMY]: {
    [DateTypeEnum.Date]: 'DD/MM/YYYY',
    [DateTypeEnum.DayDate]: 'MMM,DD/MM/YYYY',
    [DateTypeEnum.TimeMDate]: 'DD/MM/YYYY,hh:mm',
    [DateTypeEnum.TimeSDate]: 'DD/MM/YYYY,hh:mm:ss',
    [DateTypeEnum.WeekYear]: '[W]W, YYYY',
    [DateTypeEnum.WeekYearDate]: '[W]W,YYYY MM/DD',
    [DateTypeEnum.MonthYear]: 'MMM-YYYY',
    [DateTypeEnum.QuarterYear]: '[Q]Q YYYY',
    [DateTypeEnum.Year]: 'YYYY'
  },
  [FormatTypeEnum.MDY]: {
    [DateTypeEnum.Date]: 'MM/DD/YYYY',
    [DateTypeEnum.DayDate]: 'MMM,MM/DD/YYYY',
    [DateTypeEnum.TimeMDate]: 'MM/DD/YYYY,hh:mm',
    [DateTypeEnum.TimeSDate]: 'MM/DD/YYYY,hh:mm:ss',
    [DateTypeEnum.WeekYear]: '[W]W, YYYY',
    [DateTypeEnum.WeekYearDate]: '[W]W,YYYY DD/MM',
    [DateTypeEnum.MonthYear]: 'MMM-YYYY',
    [DateTypeEnum.QuarterYear]: '[Q]Q YYYY',
    [DateTypeEnum.Year]: 'YYYY'
  },
  [FormatTypeEnum.YMD]: {
    [DateTypeEnum.Date]: 'YYYY/MM/DD',
    [DateTypeEnum.DayDate]: 'DD/MM/YYYY,MMM',
    [DateTypeEnum.TimeMDate]: 'YYYY/MM/DD hh:mm',
    [DateTypeEnum.TimeSDate]: 'YYYY/MM/DD hh:mm:ss',
    [DateTypeEnum.WeekYear]: 'YYYY-[W]W',
    [DateTypeEnum.WeekYearDate]: '[W]W,YYYY MM/DD',
    [DateTypeEnum.MonthYear]: 'YYYY-MM',
    [DateTypeEnum.QuarterYear]: 'YYYY-[Q]Q',
    [DateTypeEnum.Year]: 'YYYY'
  }
}

/**
 * @description: 获取缓存日期格式化类型
 * @return {string}
 */
export const getStoreDateFormatType = () => {
  const userStore = useUserStore()
  return (userStore.userSettings?.dateFormat || '0') + ''
}

/**
 * @description: 获取日期格式化 label 字符串
 * @param {object} [formatProps]
 * @param {string} [formatProps.formatType] 格式化类型枚举
 * @param {string} [formatProps.dateType] 日期类型枚举
 * @return {string}
 */
export const getDateFormatLabel = (formatProps) => {
  const defaultFormatProps = {
    formatType: getStoreDateFormatType(),
    dateType: DateTypeEnum.Date
  }
  const { formatType, dateType } = { ...defaultFormatProps, ...formatProps }
  const dateFormatLabel = DateFormatTypeParseMap[formatType][dateType]
  return dateFormatLabel
}

/**
 * @description: 获取日期格式化 value 字符串
 * @param {object} formatValueProps
 * @param {(object|string)} [formatValueProps.date] 日期字符串或者表示日期的对象 默认当前日期
 * @param {string} [formatValueProps.formatType] 格式化类型枚举
 * @param {string} [formatValueProps.dateType] 日期类型枚举
 * @return {string}
 */
export const getDateFormatValue = (formatValueProps) => {
  const { date = new Date() } = formatValueProps
  const dateFormatLabel = getDateFormatLabel({ ...formatValueProps })

  if (isString(dateFormatLabel)) {
    const dateFormatValue = dayjs(date).format(dateFormatLabel)
    return dateFormatValue
  }
  if (isFunction(dateFormatLabel)) {
    return dateFormatLabel(dayjs(date))
  }
  const dateFormatLabelType = typeof dateFormatLabel
  throw Error(`dateFormatLabel type is ${dateFormatLabelType}`)
}

/**
 * @description: 获取解析 map对象 根据 formatType
 * @param {string} [formatType] 格式化类型 默认缓存设置
 * @return {object}
 */
export const getParseMapByFormatType = (formatType = getStoreDateFormatType()) => {
  const parseMap = { ...DateFormatTypeParseMap[formatType] }
  return parseMap
}
