
const state = () => ({
  filterData: {}
})

const actions = {
}

export default {
  state,
  actions,
  persist: {
    enabled: true,
    isCommon: false,
    isLogoutClearStorage: false,
    isModuleStorage: true,
    watchFields: ['filterData']
  }
}
