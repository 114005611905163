
const state = () => ({
  personalCacheBy1P: {
    isFullNumber: false,
    isShowChart: true,
    currentTab: '',
    defaultPanelChoseTab1: [],
    latestDataTab1: null,
    latestDataTab3: null,
    QueryModelTab1: {},
    QueryModelTab2: {},
    QueryModelTab3: {}
  },
  personalCacheBy3P: {
    currentTab: '',
    QueryModelTabMSKU: {},
    QueryModelTabASIN: {},
    QueryModelTabLedger1: {},
    QueryModelTabLedger2: {},
    returnTrendDateType: '',
    returnTopTenDateType: '',
    isFullNumber: false,
    QueryModelTabReturn0: {},
    QueryModelTabReturn1: {},
    QueryModelTabReturn2: {},
    returnTopTenChartType: ''
  }
})
const actions = {}
export default {
  state,
  actions,
  persist: {
    enabled: true,
    isCommon: false,
    isModuleStorage: true,
    watchFields: ['personalCacheBy1P', 'personalCacheBy3P'],
    isLogoutClearStorage: false
  }
}
