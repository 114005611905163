
const state = () => ({
  alertOverviewBy1P: {
    filterCache: {},
    selectTypesObj: {}
  },
  alertOverviewBy3P: {
    filterCache: {},
    tabType: '',
    roiChartType: ''
  }
})

const actions = {
}

export default {
  state,
  actions,
  persist: {
    enabled: true,
    isCommon: false,
    isLogoutClearStorage: false,
    isModuleStorage: true,
    watchFields: ['alertOverviewBy1P', 'alertOverviewBy3P']
  }
}
