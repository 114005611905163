const state = () => ({
  ByProduct: {},
  ByCategory: {},
  currentTab: '',
  commonData: {}
})
const actions = {

}
export default {
  state,
  actions,
  persist: {
    enabled: true,
    isCommon: false,
    isModuleStorage: true,
    watchFields: ['ByProduct', 'ByCategory', 'currentTab', 'commonData'],
    isLogoutClearStorage: false
  }
}
